// -- external libreray --
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
  AlertController,
  LoadingController,
  MenuController,
  ModalController,
  Platform,
  ToastController,
} from '@ionic/angular';
// -- const { Storage } = Plugins;
// -- import { Storage } from '@ionic/storage';
import { Storage } from '@ionic/storage';

import { HttpCancelService } from './http-cancel.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private ruteDev = 'http://192.168.0.13:8888/smartge/appsserver/empresarial/public/api/';
  private ruteDevDocs = 'http://192.168.0.13:8888/smartge/appsserver/empresarial/storage/app/';
  private ruteProd = 'https://www.empresarial.smartge.co/empresarial/public/api/';
  private ruteProdDocs = 'https://www.empresarial.smartge.co/empresarial/storage/app/';
  private ruteNewWinLoc = 'http://192.168.0.6:8100/#/';
  private ruteNewWinProd = 'https://www.smartge.co/#/';
  public gblUrl = '';
  public gblUrlDocs = '';
  public gblUrlNewWin = '';
  public appID = 2;
  public appPages: any = null;
  public appBadges = null;
  public rol = null;
  public prod = true;
  public socket: any;
  public error: string;
  public token: any;
  public isMobile = false;
  public alreadyDismissed = false;
  public dataSet: any;
  public detail: any;
  public typeMenu = 'overlay';
  private showReloadMenu = true;

  // #-- ------general----- --
  public entidadInfo = new BehaviorSubject(null);
  public userInfo = new BehaviorSubject(null);
  public authenticationState = new BehaviorSubject(null);
  public changesBadges = new BehaviorSubject(null);
  // #-- ------general----- --
  // #-- ----inventario---- --
  public changesBodegaList = new Subject();
  public changesUnidadMedidaList = new Subject();
  public changesFamiliaList = new Subject();
  public changesCategoriaList = new Subject();
  public changesItemList = new Subject();
  public changesImpuestoList = new Subject();
  public changesTrasladoItemList = new Subject();
  public changesAjusteList = new Subject();
  public changesDescuentoList = new Subject();
  public changesCaracteristicaList = new Subject();
  // #-- ----inventario---- --
  // #-- -----terceros----- --
  public changesTerceroList = new Subject();
  // #-- -----terceros----- --

  // #-- -----facturacion----- --
  public changesFVentaList = new Subject();
  public changesFCompraList = new Subject();
  public changesPVentaList = new Subject();
  public changesRCajaList = new Subject();
  public changesPCompraList = new Subject();
  // #-- -----facturacion----- --
  // #-- ----administrativo--- --
  public changesConceptoList = new Subject();
  public changesBancoList = new Subject();
  public changesCentroCostoList = new Subject();
  public changesRetencionList = new Subject();
  public changesRemisionList = new Subject();
  // #-- ------general----- --

  // #-- --------pos-------- --
  public changesTerminalList = new Subject();
  public changesTurnoList = new Subject();
  // #-- --------pos-------- --

  // #-- ----produccion----- --
  public changesTipoProduccionList = new Subject();
  public changesLoteList = new Subject();
  public changesColeccionList = new Subject();
  public changesProduccionList = new Subject();
  // #-- ----produccion----- --

  public selects = {
    caracteristica: [],
    bodega: [],
    item: [],
    unidMed: [],
    categoria: [],
    familia: [],
    impuesto: [],
    tercero: [],
    proveedor: [],
    cliente: [],
    funcionario: [],
    tipoDoc: [],
    tipoPago: [],
    plazo: [],
    precios: [],
    ccosto: [],
    retencion: [],
    fVentas: [],
    fCompras: [],
    bancos: [],
    facturas: [],
    conceptos: [],
    terminales: [],
    turnos: [],
    lotes: [],
    tipoProduccion: [],
    colecciones: [],
  };

  public datePicker: any = {
    inputDate: new Date(),
    closeOnSelect: true, // default false
    mondayFirst: true, // default false
    setLabel: 'Aplicar', // default 'Set'
    todayLabel: 'Hoy', // default 'Today'
    closeLabel: 'Cerrar', // default 'Close'
    dateFormat: 'DD/MM/YYYY', // default DD MMM YYYY
    titleLabel: 'Seleccione una fecha', // default null
    monthsList: [
      'Enero',
      'Febrero',
      'Marzo',
      'April',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
    weeksList: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
    momentLocale: 'es', // Default 'en-US'
    btnCloseSetInReverse: true, // Default false
  };

  public errorApp = [
    {
      id: 0,
      label: 'Error',
      msn: 'Problemas con la respuesta de la aplicación servidora.',
    },
    { id: 1, label: 'Error', msn: 'Resolviendo problemas con la conexión...' },
    {
      id: 2,
      label: 'Error',
      msn: 'No puede acceder a la función en la aplicion servidora, contactese con el administrador del sistema.',
    },
    {
      id: 3,
      label: 'Error',
      msn: 'No se encontró un usuario autenticado, por favor vuelva iniciar sesión.',
    },
  ];

  public jwtHelper: JwtHelperService = new JwtHelperService();
  constructor(
    private http: HttpClient,
    public sanitizer: DomSanitizer,
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
    public toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private plt: Platform,
    private menu: MenuController,
    private ngZone: NgZone,
    private storage: Storage,
    private httpCancelService: HttpCancelService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    // --this.token = this.getToken();
    if (this.prod) {
      this.gblUrl = this.ruteProd;
      this.gblUrlDocs = this.ruteProdDocs;
      this.gblUrlNewWin = this.ruteNewWinProd;
    } else {
      this.gblUrl = this.ruteDev;
      this.gblUrlDocs = this.ruteDevDocs;
      this.gblUrlNewWin = this.ruteNewWinLoc;
    }
  }

  // -- ------------------------------- --
  // -- se puede crear un servicio indep--
  async setString(key: string, value: any) {
    await this.storage.set(key, value);
  }
  async getString(key: string) {
    await this.storage.get(key).then((val) => {
      return val;
    });
  }
  async removeItem(key: string) {
    await this.storage.remove(key);
  }

  /*
    async setString(key: string, value: string) {
        await Storage.set({ key, value });
    }
    async getString(key: string): Promise<{ value: any }> {
        return (await Storage.get({ key }));
    }
    async setObject(key: string, value: any) {
        await Storage.set({ key, value: JSON.stringify(value) });
    }
    async getObject(key: string): Promise<{ value: any }> {
        const ret = await Storage.get({ key });
        return JSON.parse(ret.value);
    }
    async removeItem(key: string) {
        await Storage.remove({ key });
    }
    */
  // -- ------------------------------- --
  // -- ------------------------------- --
  // ordenamiento de arreglos
  public sort_array(array: any) {
    array.sort((a: any, b: any) => {
      return a.id - b.id;
    });

    return array;
  }
  // fin ordenamiento
  public getDataSet() {
    return this.dataSet;
  }

  public setDataSet(data) {
    this.dataSet = data;
  }

  public openURL(title, url) {
    window.open(url, '_blank', 'location=yes');
  }

  public openMenu(e) {
    this.menu.enable(true, 'main');
  }

  public closeMenu(e) {
    this.menu.enable(false, 'main');
  }

  async showToast(message, duration, closeButton, position, color, cssClass) {
    let arrayBtn = [];
    if (closeButton) {
      arrayBtn = [
        {
          text: 'Cerrar',
          role: 'cancel',
          handler: () => {
            return
          },
        },
      ];
    }

    const toastError = await this.toastCtrl.create({
      message,
      duration,
      buttons: arrayBtn,
      position,
      color,
      cssClass,
    });

    toastError.onDidDismiss();
    toastError.present();
  }

  async showMessage(titulo, Desc) {
    const alert = await this.alertCtrl.create({
      header: titulo,
      message: Desc,
      buttons: ['OK'],
    });
    await alert.present();
  }

  async ionViewDidLoad() {
    this.alreadyDismissed = false;
    await this.loadingCtrl.dismiss();
  }

  async showLoading(msn) {
    if (this.alreadyDismissed) {
      await this.loadingCtrl.dismiss();
    }
    this.alreadyDismissed = true;
    await this.loadingCtrl
      .create({
        message: msn,
      })
      .then((a) => {
        a.present().then(() => {
          if (!this.alreadyDismissed) {
            a.dismiss();
          }
        });
      });
  }

  public getDateClient() {
    const da1 = new Date();
    return moment(da1).format('YYYY-MM-DD HH:mm:ss');
  }

  public getDateReport() {
    // -- let da1 = new Date();
    return moment().format('DD/MM/YYYY HH:mm:ss');
  }

  public numberMask(number: string) {
    return ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    // -- (000) 000-0000
  }

  public isMobileEvent() {
    if (
      this.plt.is('ios') ||
      this.plt.is('ipad') ||
      this.plt.is('iphone') ||
      this.plt.is('android') ||
      this.plt.is('mobile') ||
      this.plt.is('mobileweb') ||
      this.plt.is('tablet')
    ) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  public loggedIn() {
    this.storage.get('smartge_empresarial_token').then((token: string) => {
      return token !== null && !this.jwtHelper.isTokenExpired(token);
    });
  }

  public userLogin() {
    return new Promise((resolve, reject) => {
      let userInfo = this.userInfo.value;
      if (userInfo === undefined || userInfo === null) {
        this.storage.get('smartge_empresarial_user').then((data: any) => {
          userInfo = data; // -- JSON.parse(data)
          this.userInfo.next(userInfo);
          resolve(userInfo);
        });
      } else {
        resolve(userInfo);
      }
    });
  }

  public entidadDefault() {
    return new Promise((resolve, reject) => {
      let entidadInfo = this.entidadInfo.value;
      if (entidadInfo === undefined || entidadInfo === null) {
        this.storage.get('smartge_empresarial_entidad').then((data: any) => {
          entidadInfo = data; // -- JSON.parse(data)
          this.entidadInfo.next(entidadInfo);
          resolve(entidadInfo);
        });
      } else {
        resolve(entidadInfo);
      }
    });
  }

  public getToken() {
    return new Promise((resolve, reject) => {
      const token = this.token;
      if (token === undefined || token === null) {
        this.storage.get('smartge_empresarial_token').then((data: any) => {
          this.token = data;
          resolve(data);
        });
      } else {
        resolve(token);
      }
    });
  }

  public getRol() {
    return new Promise((resolve, reject) => {
      resolve(this.rol);
    });
  }

  async iniMenu(entidad) {
    this.menu.enable(true, 'main');
    if (this.appPages === null) {
      this.appPages = [];
      const token = await this.getToken();
      const entidadInfo = entidad ?? (await this.entidadDefault());

      this.requestPost(
        'global/getMenu',
        { appID: this.appID, sedeID: entidadInfo.sedeID },
        'jwt',
        token
      ).subscribe(
        (data) => {
          if (data.validation === 'ok') {
            this.appPages = data.menu;
            this.rol = data.rol;

            return;
          }
          this.reloadMenu();
          this.failRequestHttp(data);
        },
        (error) => {
          this.reloadMenu();
          this.errorRequestHttp(error);
        }
      );
    }
  }

  reloadMenu() {
    if (this.showReloadMenu) {
      this.iniMenu(null);
      this.showReloadMenu = false;
    }
  }

  /*-- Modelo de request http --*/
  public requestPost(funct, params, header, token): Observable<any> {
    // -- var outsideStream = this.ngZone.runOutsideAngular(() => {
    let httpOptions: any;
    switch (header) {
      case 'jwt':
        httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }),
        };
        break;
      case 'doc':
        httpOptions = {
          headers: new HttpHeaders({ Authorization: `Bearer ${token}` }),
        };
        // -- httpOptions = { headers: new HttpHeaders()};
        break;
      case 'noauth':
        httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        };
        break;
    }
    return this.http
      .post(this.gblUrl + funct, params, httpOptions)
      .pipe(tap(), catchError(this.handleError));
  }

  public requestExport(funct, param, token): Observable<any> {
    const httpOptions: any = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/octet-stream',
      }),
      responseType: 'blob',
      params: param,
    };
    return this.http.get(this.gblUrl + funct, httpOptions).pipe(tap());
  }

  public requestPostFile(funct, token): Observable<any> {
    return this.http
      .get(this.gblUrl + funct, {
        headers: new HttpHeaders({
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/octet-stream',
        }),
        responseType: 'blob',
      })
      .pipe(tap());
  }

  public requestPostExternal(url, params): Observable<any> {
    let httpOptions: any;
    httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.post(url, params, httpOptions).pipe(tap(), catchError(this.handleError));
  }

  public requestPostInternal(url, params): Observable<any> {
    return this.http.get(url).pipe(tap(), catchError(this.handleError));
  }

  private handleError(data: HttpErrorResponse) {
    console.log('handleError', data);
    if (data.error instanceof ErrorEvent) {
      console.log('Error desde el cliente: ', data.error.message);
    } else {
      console.log(`Error desde el server: code, ${data.status} body was: ${data.message}`);
    }
    return throwError(data);
  }

  public failRequestHttp(data) {
    console.log('failRequestHttp', data);
    this.ionViewDidLoad();
    let msn = this.errorApp[2].msn;
    if (data.validation === 'fail') {
      if (data.message !== '') {
        msn = data.message;
      }
      this.showMessage('Error', msn);
    }
  }

  public errorRequestHttp(error) {
    this.httpCancelService.cancelPendingRequests();
    console.log('errorRequestHttp', error);
    this.ionViewDidLoad();
    if (error.statusText !== 'Unauthorized') {
      if (error.status === 0 && error.url === null) {
        this.showToast(this.errorApp[1].msn, 5000, true, 'bottom', 'danger', '');
      } else {
        this.showMessage(this.errorApp[0].label, this.errorApp[0].msn);
      }
    } else {
      this.logout();
      this.showMessage(this.errorApp[3].label, this.errorApp[3].msn);
    }
  }

  async logout() {
    this.menu.enable(false, 'main');
    this.router.navigate(['/login', {}]);

    await this.showLoading('Cerrando sesión...');
    const token = await this.getToken();
    if (token) {
      this.requestPost(
        'auth/logout',
        { tokenFCM: '' /*this.notificationsService.tokenFCM*/ },
        'jwt',
        token
      ).subscribe((data) => {});
    }

    setTimeout(() => {
      this.removeItem('smartge_empresarial_token');
      this.removeItem('smartge_empresarial_user');
      this.removeItem('smartge_empresarial_entidad');

      this.token = null;
      this.authenticationState.next(false);
      this.userInfo.next(null);
      this.entidadInfo.next(null);
      this.appPages = null;
      this.rol = null;
      // --this.socketService.socket.disconnect();
      // --this.navCtrl.setRoot(link, section);
      this.ionViewDidLoad();
    }, 500);
  }
  /*-- ----------------------- */
}

import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular'; //NavController
import { Router } from '@angular/router';
import { AuthService } from './../../config/services/auth.service';
import { GlobalService } from './../../config/services/global.service';
@Component({
  selector: 'app-menu-general',
  templateUrl: './menu-general.component.html',
  styleUrls: ['./menu-general.component.scss'],
})
export class MenuGeneralComponent implements OnInit {
  @Input('menu') menu;
  @Input('menuSel') menuSel;
  @Input('permisos') permisos;
  @Input('rol') rol;

  public menuPages: any = [
    { nombre: 'Resumen', icono: 'analytics-outline', url: 'dashboard', params: {} },
    { nombre: 'Mi perfil', icono: 'people-circle-outline', url: 'perfil', params: {} },
    { nombre: 'Configuración', icono: 'cog-outline', url: 'entidad', params: {} },
    { nombre: 'Cerrar sesión', icono: 'log-out-outline', url: 'login', params: {} },
  ];

  public menuPeriodo: any = [
    { nombre: 'Todo', icono: 'list', value: 'all' },
    { nombre: '1 Mes', icono: 'time', value: '1' },
    { nombre: '3 Meses', icono: 'time', value: '3' },
    { nombre: '6 Meses', icono: 'time', value: '6' },
    { nombre: '1 Año', icono: 'time', value: '12' },
  ];

  public fVentas: any = [
    {
      nombre: 'Editar',
      icono: 'create-outline',
      value: 'edit',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Generar remisión',
      icono: 'list-outline',
      value: 'addRemision',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Imprimir factura',
      icono: 'print-outline',
      value: 'imprimir',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Imprimir copia',
      icono: 'documents-outline',
      value: 'imprimirCopia',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Anular factura',
      icono: 'close-circle-outline',
      value: 'anular',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Factura electrónica',
      icono: 'earth-outline',
      value: 'facElectronica',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Correo electrónico',
      icono: 'mail-outline',
      value: 'enviarEmail',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Detalle',
      icono: 'document',
      value: 'detalle',
      lines: 'none',
      view: true,
      class: 'cursorP',
    },
    {
      nombre: 'Pagos',
      icono: 'cash-outline',
      value: 'pagos',
      lines: 'none',
      view: true,
      class: 'cursorP',
    },
    {
      nombre: 'Versiones',
      icono: 'git-branch',
      value: 'cVersion',
      lines: 'none',
      view: true,
      class: 'cursorP',
    },
  ];

  public fCompras: any = [
    {
      nombre: 'Editar',
      icono: 'create-outline',
      value: 'edit',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Exportar factura',
      icono: 'cloud-download-outline',
      value: 'exportar',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Imprimir factura',
      icono: 'print-outline',
      value: 'imprimir',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Imprimir copia',
      icono: 'documents-outline',
      value: 'imprimirCopia',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Anular factura',
      icono: 'close-circle-outline',
      value: 'anular',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Correo electrónico',
      icono: 'mail-outline',
      value: 'enviarEmail',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Detalle',
      icono: 'document',
      value: 'detalle',
      lines: 'none',
      view: true,
      class: 'cursorP',
    },
    {
      nombre: 'Pagos',
      icono: 'cash-outline',
      value: 'pagos',
      lines: 'none',
      view: true,
      class: 'cursorP',
    },
    {
      nombre: 'Versiones',
      icono: 'git-branch',
      value: 'cVersion',
      lines: 'none',
      view: true,
      class: 'cursorP',
    },
  ];

  public PVentas: any = [
    {
      nombre: 'Editar',
      icono: 'create-outline',
      value: 'edit',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Exportar pago',
      icono: 'cloud-download-outline',
      value: 'exportar',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Imprimir pago',
      icono: 'print-outline',
      value: 'imprimir',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Imprimir copia',
      icono: 'documents-outline',
      value: 'imprimirCopia',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Anular pago',
      icono: 'close-circle-outline',
      value: 'anular',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Detalle',
      icono: 'document',
      value: 'detalle',
      lines: 'none',
      view: true,
      class: 'cursorP',
    },
    {
      nombre: 'Facturas',
      icono: 'reader-outline',
      value: 'facturas',
      lines: 'none',
      view: true,
      class: 'cursorP',
    },
    {
      nombre: 'Versiones',
      icono: 'git-branch',
      value: 'cVersion',
      lines: 'none',
      view: true,
      class: 'cursorP',
    },
  ];

  public reCaja: any = [
    {
      nombre: 'Editar',
      icono: 'create-outline',
      value: 'edit',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Exportar recibo de caja',
      icono: 'cloud-download-outline',
      value: 'exportar',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Imprimir recibo de caja',
      icono: 'print-outline',
      value: 'imprimir',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Imprimir copia',
      icono: 'documents-outline',
      value: 'imprimirCopia',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Anular recibo de caja',
      icono: 'close-circle-outline',
      value: 'anular',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Detalle',
      icono: 'document',
      value: 'detalle',
      lines: 'none',
      view: true,
      class: 'cursorP',
    },
    {
      nombre: 'Versiones',
      icono: 'git-branch',
      value: 'cVersion',
      lines: 'none',
      view: true,
      class: 'cursorP',
    },
  ];

  public menuCard: any = [
    {
      nombre: 'Editar',
      icono: 'create',
      value: 'edit',
      lines: 'inset',
      view: false,
      class: 'bold cursorP',
    },
    {
      nombre: 'Trámitar requerimiento',
      icono: 'checkmark-circle',
      value: 'tramitar',
      lines: 'inset',
      view: false,
      class: 'bold cursorP',
    },
    {
      nombre: 'Asignar funcionario',
      icono: 'people',
      value: 'asignar',
      lines: 'inset',
      view: false,
      class: 'bold cursorP',
    },
    {
      nombre: 'Cambiar dependencia',
      icono: 'map',
      value: 'cambiarDep',
      lines: 'inset',
      view: false,
      class: 'bold cursorP',
    },
    {
      nombre: 'Anular radicado',
      icono: 'remove-circle-outline',
      value: 'anular',
      lines: 'inset',
      view: false,
      class: 'bold cursorP',
    },
    {
      nombre: 'Clonar',
      icono: 'copy',
      value: 'clonar',
      lines: 'inset',
      view: false,
      class: 'bold cursorP',
    },
    {
      nombre: 'Generar rótulo entrada',
      icono: 'barcode',
      value: 'printRotulo',
      lines: 'inset',
      view: false,
      class: 'bold cursorP',
    },
    {
      nombre: 'Detalle',
      icono: 'document',
      value: 'radicado',
      lines: 'none',
      view: true,
      class: 'cursorP',
    },
    {
      nombre: 'Trámite',
      icono: 'mail-open',
      value: 'tramite',
      lines: 'none',
      view: false,
      class: 'cursorP',
    },
    {
      nombre: 'Documentos',
      icono: 'folder',
      value: 'archivo',
      lines: 'none',
      view: true,
      class: 'cursorP',
    },
    {
      nombre: 'Versiones',
      icono: 'git-branch',
      value: 'cVersion',
      lines: 'none',
      view: true,
      class: 'cursorP',
    },
    {
      nombre: 'Discusión',
      icono: 'chatboxes',
      value: 'discusion',
      lines: 'none',
      view: true,
      class: 'cursorP',
    },
  ];

  public remision: any = [
    {
      nombre: 'Editar',
      icono: 'create-outline',
      value: 'edit',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Imprimir remisión',
      icono: 'print-outline',
      value: 'imprimir',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Anular remisión',
      icono: 'close-circle-outline',
      value: 'anular',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Correo electrónico',
      icono: 'mail-outline',
      value: 'enviarEmail',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Detalle',
      icono: 'document',
      value: 'detalle',
      lines: 'none',
      view: true,
      class: 'cursorP',
    },
    {
      nombre: 'Versiones',
      icono: 'git-branch',
      value: 'cVersion',
      lines: 'none',
      view: true,
      class: 'cursorP',
    },
  ];

  public produccion: any = [
    {
      nombre: 'Editar',
      icono: 'create-outline',
      value: 'edit',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Exportar producción',
      icono: 'cloud-download-outline',
      value: 'exportar',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Imprimir producción',
      icono: 'print-outline',
      value: 'imprimir',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Imprimir copia',
      icono: 'documents-outline',
      value: 'imprimirCopia',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Anular producción',
      icono: 'close-circle-outline',
      value: 'anular',
      lines: 'inset',
      view: true,
      class: 'boldx cursorP',
    },
    {
      nombre: 'Detalle',
      icono: 'document',
      value: 'detalle',
      lines: 'none',
      view: true,
      class: 'cursorP',
    },
    {
      nombre: 'Versiones',
      icono: 'git-branch',
      value: 'cVersion',
      lines: 'none',
      view: true,
      class: 'cursorP',
    },
  ];

  constructor(
    private authService: AuthService,
    private globalService: GlobalService,
    private popoverCtrl: PopoverController,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.menu === 'menuCard') {
      this.globalService.userLogin().then((user) => {
        // -- const userLog: any = user;
      });
    }
  }

  async goMenu(page, ev) {
    try {
      switch (page.url) {
        case 'login':
          this.globalService.logout();
          break;
        default:
          this.router.navigate([`/${page.url}`, page.params]);
          break;
      }
      await this.popoverCtrl.dismiss();
    } catch (e) {}
  }

  async goGeneral(values, ev) {
    try {
      await this.popoverCtrl.dismiss({
        value: values,
      });
    } catch (e) {}
  }

  async goCardEntrada(values, ev) {
    try {
      await this.popoverCtrl.dismiss({
        value: values,
      });
    } catch (e) {}
  }
}

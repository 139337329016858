import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, ModalController} from '@ionic/angular';
import { GlobalService } from './../../../../config/services/global.service';

@Component({
    selector: 'app-modal-retencion',
    templateUrl: './modal-retencion.component.html',
    styleUrls: ['./modal-retencion.component.scss'],
})
export class ModalRetencionComponent implements OnInit {
    @ViewChild('retencionSearchBar')retencionSearchBar;
    
    @Input() select: string;
    @Input() tipo: string;

    public retencionList:any;
	  public loadRetencion:    boolean = true;
    public loadRetencionIni: boolean = false;
    public showSearch:    boolean = false;
    public from: any;
    public itemSelect = [];

  	constructor( 
  		private globalService: GlobalService,
  		private router: Router,
  		public  modalController: ModalController
  	) {
  		this.retencionList = {list: [], page: 0, pages: 0, iTotal: 0, search: ''}
  	}

  	ngOnInit() {
  		if(this.globalService.selects.retencion.length <= 0){
        	this.list('ini', null, 'Cargando...');
  		}else{
  			this.retencionList.list = this.globalService.selects.retencion;
  		}
        this.focusSearch();
  	}
    
    focusSearch() {
        setTimeout(() =>{
        	this.retencionSearchBar.setFocus();
        },400);
    }

  	async list(from, ev, loadinSMS) {
  		const token       = await this.globalService.getToken();
        const entidad:any = await this.globalService.entidadDefault();

        this.retencionList.page       = this.retencionList.page+1;
        let params                 = {};
            params['take']         = 500;
            params['page']         = this.retencionList.page;
            params['search']       = this.retencionList.search;
            params['appID']        = this.globalService.appID;
            params['sedeID']       = entidad.sedeID;
            params['retencionID']     = null;
        this.loadRetencion = true;
        this.globalService.requestPost('global/retencion', params, 'jwt', token).subscribe(
            data => {
                if(data.validation == 'ok'){
                    let disabled = true;
                    if(data.list.length > 0){ 
                    	this.globalService.selects.retencion = data.list;
                        this.retencionList.list = data.list;
                        disabled = false;
                    }
                    this.loadRetencion    = false;
                    this.loadRetencionIni = true;
                    if(this.showSearch){
                    	this.focusSearch();
                    }
                }else{
                    this.globalService.failRequestHttp(data);
                }
            },
            error => {
                this.globalService.errorRequestHttp(error)
            }
        );
  	}

  	async retencionSelect(item, ev) {
        if(this.select == 'single') {
            this.modalController.dismiss({
                'retencionInfo': item
            });
        }else if(this.select == 'singleMultiple') {
            this.itemSelect.push(item);
            this.modalController.dismiss({
                'retencionInfo': item
            });
        }
    }

  	async dismissModal(ev) {
        this.modalController.dismiss({
            'retencionInfo': null
        })
    }
}




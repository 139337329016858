import { map } from 'rxjs/operators';

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';

import { GlobalService } from '../../../../config/services/global.service';
import { RowTipoProduccion, TipoProduccionList } from '../../../../models/produccion.models';

@Component({
  selector: 'app-modal-tipo-produccion',
  templateUrl: './modal-tipo-produccion.component.html',
  styleUrls: ['./modal-tipo-produccion.component.scss'],
})
export class ModalTipoProduccionComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild('tipoProduccionSearchBar') tipoProduccionSearchBar;

  @Input() select: string;
  @Input() tipo: string;
  @Input() selected: RowTipoProduccion;

  public tipoProduccionList: TipoProduccionList;
  public loadTipoProduccion = true;
  public loadTipoProduccionIni = false;
  public showSearch = false;
  public from: any;
  public itemSelect = [];

  constructor(
    private globalService: GlobalService,
    private router: Router,
    public modalController: ModalController
  ) {
    this.tipoProduccionList = { list: [], page: 0, pages: 0, iTotal: 0, search: '' };
  }

  ngOnInit() {
    if (this.globalService.selects.tipoProduccion.length <= 0) {
      this.list('ini', null, 'Cargando...');
    } else {
      this.tipoProduccionList.list = this.globalService.selects.tipoProduccion;
      if (this.selected !== undefined || this.selected != null) {
        this.tipoProduccionList.list = this.tipoProduccionList.list.filter(
          (i) => i.id !== this.selected.id
        );
      }
    }
    this.focusSearch();
  }

  focusSearch() {
    setTimeout(() => {
      this.tipoProduccionSearchBar.setFocus();
    }, 400);
  }

  goInfinite(ev) {
    this.list('scroll', ev, '');
  }

  async list(from, ev, loadinSMS) {
    const token = await this.globalService.getToken();
    const entidad: any = await this.globalService.entidadDefault();

    this.tipoProduccionList.page = this.tipoProduccionList.page + 1;
    const params = {};
    params['take'] = 500;
    params['page'] = this.tipoProduccionList.page;
    params['search'] = this.tipoProduccionList.search;
    params['appID'] = this.globalService.appID;
    params['sedeID'] = entidad.sedeID;
    params['tipoProduccionID'] = null;
    this.loadTipoProduccion = true;
    this.globalService.requestPost('prod/tipoProduccion', params, 'jwt', token).subscribe(
      (data) => {
        if (data.validation === 'ok') {
          let disabled = true;
          if (data.list.length > 0) {
            this.globalService.selects.tipoProduccion = data.list;

            // -- para que no aparezca el tipoProduccion seleccionado
            if (this.selected !== undefined) {
              data.list = data.list.filter((i) => i.id !== this.selected.id);
            }
            // -- this.tipoProduccionList.list = data.list;
            data.list.map((row) => {
              this.tipoProduccionList.list.push(row);
            });
            disabled = false;
          }

          if (ev != null) {
            ev.target.disabled = disabled;
            ev.target.complete();
          }

          this.loadTipoProduccion = false;
          this.loadTipoProduccionIni = true;
          if (this.showSearch) {
            this.focusSearch();
          }
        } else {
          this.globalService.failRequestHttp(data);
        }
      },
      (error) => {
        this.globalService.errorRequestHttp(error);
      }
    );
  }

  async tipoProduccionSelect(item, ev) {
    if (this.select === 'single') {
      this.modalController.dismiss({
        tipoProduccionInfo: item,
      });
    } else if (this.select === 'singleMultiple') {
      this.itemSelect.push(item);
      this.modalController.dismiss({
        tipoProduccionInfo: item,
      });
    }
  }

  async dismissModal(ev) {
    this.modalController.dismiss({
      tipoProduccionInfo: null,
    });
  }
}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, ModalController} from '@ionic/angular';
import { GlobalService } from './../../../../config/services/global.service';
import { CategoriaList } from './../../../../models/inventario.models';
//import { SelRol, SelMenuPermisos } from './../../../../models/global.models';

@Component({
  selector: 'app-modal-categoria',
  templateUrl: './modal-categoria.component.html',
  styleUrls: ['./modal-categoria.component.scss'],
})
export class ModalCategoriaComponent implements OnInit {
	@ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
    @ViewChild('categoriaSearchBar')categoriaSearchBar;
    
    @Input() select: string;
    @Input() tipo: string;
    @Input() categoriaID: string;
    @Input() famSelect: [];

    public categoriaList: CategoriaList;
    //public rol:         SelRol;
    //public permisos:    SelMenuPermisos;

    public search_categoria:   string  = '';
    public loadCategoria:      boolean = true;
    public loadCategoriaIni:   boolean = false;
    public from: any;
    public subscriptionCategoria:any;
    public itemSelect = [];

  	constructor( 
  		private globalService: GlobalService,
        private router: Router,
        public  modalController: ModalController
  	) {
        this.categoriaList = {list: [], page: 0, pages: 0, iTotal: 0, search: ''}
    }

  	ngOnInit() {
  		if(this.famSelect !== undefined){
            this.itemSelect = this.famSelect;
        }
        if(this.globalService.selects.categoria.length <= 0){
            this.list('ini', null, 'Cargando...');
        }else{
            this.categoriaList.list = this.globalService.selects.categoria;
        }
        this.subscriptionCategoria = this.globalService.changesCategoriaList.subscribe(categoria => { 
            this.subscribeSave(categoria)
        })
        this.focusSearch();
  	}

    ngOnDestroy() {
        this.subscriptionCategoria.unsubscribe();
    }

    subscribeSave(categoria) {
        if(categoria != null) {
            if(categoria.tipo == 'add') {
                this.categoriaList.list.unshift(categoria.categoriaList);
            }else{
                for (var i = this.categoriaList.list.length - 1; i >= 0; i--) {
                    if(this.categoriaList.list[i].id == categoria.idLast){
                    	if(categoria.tipo == 'edit') {
                            this.categoriaList.list[i] = categoria.categoriaList;
                        }else{
                            this.categoriaList.list.splice(i,1);
                        }
                        break
                    }
                }
            }
        }
    }

    categoriaSearch(ev) {
        this.categoriaList.page = 0;
        this.categoriaList.list = [];
        this.list('ini', null, 'Buscando...');
    }

    categoriaClearSearch(ev) {
    	if(this.categoriaList.search != ''){
	        this.categoriaList.page   = 0;
	        this.categoriaList.search = '';
	        this.categoriaList.list   = [];
	    }
    }

    focusSearch() {
        setTimeout(() =>{
        	this.categoriaSearchBar.setFocus();
        },400);
    }

  	goInfinite(ev) {
        this.list('scroll', ev, '');
    }

  	async list(from, ev, loadinSMS) {
  		const token       = await this.globalService.getToken();
        const entidad:any = await this.globalService.entidadDefault();

        this.categoriaList.page      = this.categoriaList.page+1;
        let params                 = {};
            params['page']         = this.categoriaList.page;
            params['search']       = this.categoriaList.search;
            params['appID']        = this.globalService.appID;
            params['sedeID']       = entidad.sedeID;
            params['categoriaID']  = null;
            params['familiaID']    = null;
        if(loadinSMS != ''){
        	//await this.globalService.showLoading('Cargando...');
        }
        this.loadCategoria = true;
        this.globalService.requestPost('invent/categoria', params, 'jwt', token).subscribe(
            data => {
                if(data.validation == 'ok'){
                    let disabled = true;
                    if(data.list.length > 0){
                        this.globalService.selects.categoria = data.list;
                        if(this.select == 'single') {
                            this.categoriaList.list = data.list;
                            // for (var i = 0; i < data.list.length; i++) {
                            //     this.categoriaList.list.push( data.list[i] );
                            // }
                        }else{
                            if(this.itemSelect.length > 0){
                                for (var i = 0; i < data.list.length; i++) {
                                    let noExiste = false;
                                    for (var j = this.itemSelect.length - 1; j >= 0; j--) {
                                        if(data.list[i].id == this.itemSelect[j].id){
                                            noExiste = false;
                                            break;
                                        }else{
                                            noExiste = true;
                                        }
                                    }
                                    if(noExiste){
                                        this.categoriaList.list.push( data.list[i] );
                                    }
                                }
                            }else{
                                this.categoriaList.list = data.list;
                            }
                        }
                        disabled = false;
                    }
                    if(ev != null){
                        ev.target.disabled = disabled;
                        ev.target.complete();
                    }

                    this.loadCategoria    = false;
                    this.loadCategoriaIni = true;
                    if(loadinSMS != ''){
                    	//this.globalService.ionViewDidLoad();
                    }
                }else{
                    this.globalService.failRequestHttp(data);
                }
            },
            error => {
                this.globalService.errorRequestHttp(error)
            }
        );
  	}

  	async newCategoria(ev) {
  		this.router.navigate(['/categoria-addedit', {'tipo': 'add'}])
  		setTimeout(() =>{
            this.modalController.dismiss({'categoriaInfo': null})
        },400);
    }

    async categoriaSelect(item,item_fam, ev) {
        let fam = []
        fam.push(item_fam);
        item.familia = fam;
        if(this.select == 'single') {
            this.modalController.dismiss({
                'categoriaInfo': item
            });
        }else if(this.select == 'singleMultiple') {
            this.itemSelect.push(item);
            this.modalController.dismiss({
                'categoriaInfo': item
            });
        }else{
            this.itemSelect.push(item);
            for (var i = this.categoriaList.list.length - 1; i >= 0; i--) {
                if(this.categoriaList.list[i].id == item.id){
                    this.categoriaList.list.splice(i,1);
                }
            }
        }
    }

    async categoriaEliminar(item, ev) {
        for (var i = this.itemSelect.length - 1; i >= 0; i--) {
            if(this.itemSelect[i].id == item.id){
                this.itemSelect.splice(i,1);
            }
        }
        this.categoriaList.list.push(item);
    }

    async dismissModal(ev) {
        this.modalController.dismiss({
            'categoriaInfo': null
        })
    }
}





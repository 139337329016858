import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, ModalController} from '@ionic/angular';
import { Validators, FormBuilder, FormGroup, FormControl} from '@angular/forms';
import { GlobalService } from './../../../../config/services/global.service';
//import { ImpuestoList } from './../../../../models/inventario.models';
import { SelPrecio, SelBodega, SelDescuento, SelCaracteristica } from './../../../../models/global.models';

@Component({
    selector: 'app-modal-item-detailadd',
    templateUrl: './modal-item-detailadd.component.html',
    styleUrls: ['./modal-item-detailadd.component.scss'],
})
export class ModalItemDetailaddComponent implements OnInit {
    @ViewChild('focusImp', null) focusImp;
    @Input() selectedRows:[];
    @Input() from:   string;
    @Input() accion: string;
    public title:    string;
    
    public dataRows = [];
    public sendInfo = {'asignar': false, rows: []}

    public selectPrecio:    Array<SelPrecio>;
    public selectBodega:    Array<SelBodega>;
    public selectDescuento: Array<SelDescuento>;
    public selectCaracteristica: Array<SelCaracteristica>;

    constructor( 
    	public formBuilder: FormBuilder, 
  		private globalService: GlobalService,
        private router: Router,
        public  modalController: ModalController
  	) {}

    ngOnInit() {
    	this.dataRows = this.selectedRows;
        switch (this.from) {
    		case 'precio':
    			this.title = 'Precios del item'
    			this.getPrecios(null);
    		break;
    		case 'bodega':
    			this.title = 'Existencias en bodega'
    			this.getBodegas(null);
    		break;
    		case 'descuento':
    			this.title = 'Descuentos del item'
    			this.getDescuentos(null);
    		break;
            case 'caracteristica':
                this.title = 'Caracteristicas del item'
                this.getCaracteristicas(null);
            break;
    	}
    }

    async dismissModal(ev) {
        this.modalController.dismiss({'asignar': false, data: null})
    }

    async dismissModalOk(close, ev) {
    	this.sendInfo.asignar = true;
    	this.sendInfo.rows    = this.dataRows;
    	this.modalController.dismiss(this.sendInfo)
    }

    async getPrecios(precioID) {
  		const token       = await this.globalService.getToken();
        const entidad:any = await this.globalService.entidadDefault();

        let params             = {};
            params['appID']    = this.globalService.appID;
            params['sedeID']   = entidad.sedeID;
            params['precioID'] = precioID;
        await this.globalService.showLoading('Cargando...');
        let post = await this.globalService.requestPost('global/precio', params, 'jwt', token).subscribe(
            data => {
                if(data.validation == 'ok'){
                    if(this.dataRows.length > 0){	
                        for (var j = this.dataRows.length - 1; j >= 0; j--) {
                        	data.precio =  data.precio.filter(i => i.preID !== this.dataRows[j].preID);
                        }
                    }
                    this.selectPrecio = data.precio;
                }else{
                    this.globalService.failRequestHttp(data);
                }
                this.globalService.ionViewDidLoad();
                post.unsubscribe();
            },
            error => {
                this.globalService.errorRequestHttp(error)
            }
        );
  	}
    
    async getBodegas(bodegaID) {
  		const token       = await this.globalService.getToken();
        const entidad:any = await this.globalService.entidadDefault();

        let params             = {};
            params['appID']    = this.globalService.appID;
            params['sedeID']   = entidad.sedeID;
            params['bodegaID'] = bodegaID;
            params['viewPpal'] = false;
            
        await this.globalService.showLoading('Cargando...');
        let post = await this.globalService.requestPost('global/bodega', params, 'jwt', token).subscribe(
            data => {
                if(data.validation == 'ok'){
                	if(this.dataRows.length > 0){	
                        for (var j = this.dataRows.length - 1; j >= 0; j--) {
                        	data.bodega =  data.bodega.filter(i => i.bodID !== this.dataRows[j].bodID);
                        }
                    }
                    this.selectBodega = data.bodega;
                }else{
                    this.globalService.failRequestHttp(data);
                }
                this.globalService.ionViewDidLoad();
                post.unsubscribe();
            },
            error => {
                this.globalService.errorRequestHttp(error)
            }
        );
  	}

    async getCaracteristicas(caracteristicaID) {
        const token       = await this.globalService.getToken();
        const entidad:any = await this.globalService.entidadDefault();

        let params             = {};
            params['appID']    = this.globalService.appID;
            params['sedeID']   = entidad.sedeID;
            params['caracteristicaID'] = caracteristicaID;
            
        await this.globalService.showLoading('Cargando...');
        let post = await this.globalService.requestPost('global/caracteristica', params, 'jwt', token).subscribe(
            data => {
                if(data.validation == 'ok'){
                    if(this.dataRows.length > 0){    
                        for (var j = this.dataRows.length - 1; j >= 0; j--) {
                            data.caracteristica =  data.caracteristica.filter(i => i.carID !== this.dataRows[j].carID);
                        }
                    }
                    this.selectCaracteristica = data.caracteristica;
                }else{
                    this.globalService.failRequestHttp(data);
                }
                this.globalService.ionViewDidLoad();
                post.unsubscribe();
            },
            error => {
                this.globalService.errorRequestHttp(error)
            }
        );
    }

  	async getDescuentos(descID) {
  		const token       = await this.globalService.getToken();
        const entidad:any = await this.globalService.entidadDefault();

        let params             = {};
            params['appID']    = this.globalService.appID;
            params['sedeID']   = entidad.sedeID;
            params['descID']   = descID;
        await this.globalService.showLoading('Cargando...');
        let post = await this.globalService.requestPost('global/descuento', params, 'jwt', token).subscribe(
            data => {
                if(data.validation == 'ok'){
                	if(this.dataRows.length > 0){	
                        for (var j = this.dataRows.length - 1; j >= 0; j--) {
                        	data.descuento =  data.descuento.filter(i => i.descID !== this.dataRows[j].descID);
                        }
                    }
                    this.selectDescuento = data.descuento;
                }else{
                    this.globalService.failRequestHttp(data);
                }
                this.globalService.ionViewDidLoad();
                post.unsubscribe()
            },
            error => {
                this.globalService.errorRequestHttp(error)
            }
        );
  	}

  	selectedPrecio(pre, ev: any) {
    	this.dataRows.unshift(pre);
    	this.sendInfo.asignar = true;
    	this.sendInfo.rows    = this.dataRows;
        this.selectPrecio 	  = this.removePrecio(this.selectPrecio, pre);
  	}
  	removePrecio(list, pre: any): Array<SelPrecio> {
  		return list.filter(p => p.preID !== pre.preID);
	}


	selectedBodega(bod, ev: any) {
    	this.dataRows.unshift(bod);
    	this.sendInfo.asignar = true;
    	this.sendInfo.rows    = this.dataRows;
        this.selectBodega 	  = this.removeBodega(this.selectBodega, bod);
  	}
  	removeBodega(list, bod: any): Array<SelBodega> {
  		return list.filter(b => b.bodID !== bod.bodID);
	}

    selectedCaracteristica(car, ev: any) {
        this.dataRows.unshift(car);
        this.sendInfo.asignar = true;
        this.sendInfo.rows         = this.dataRows;
        this.selectCaracteristica  = this.removeCaracteristica(this.selectCaracteristica, car);
    }
    removeCaracteristica(list, car: any): Array<SelCaracteristica> {
          return list.filter(b => b.carID !== car.carID);
    }

	selectedDescuento(desc, ev: any) {
    	this.dataRows.unshift(desc);
    	this.sendInfo.asignar = true;
    	this.sendInfo.rows    = this.dataRows;
        this.selectDescuento  = this.removeDescuento(this.selectDescuento, desc);
  	}
  	removeDescuento(list, desc: any): Array<SelDescuento> {
  		return list.filter(d => d.descID !== desc.descID);
	}


  	selectedDelete(row, ev) {
  		switch (this.from) {
    		case 'precio':
    			this.selectPrecio.push(row);
                this.dataRows = this.dataRows.filter(i => i.preID !== row.preID);
    		break;
    		case 'bodega':
    			this.selectBodega.push(row);
                this.dataRows = this.dataRows.filter(i => i.bodID !== row.bodID);
    		break;
            case 'caracteristica':
                this.selectCaracteristica.push(row);
                this.dataRows = this.dataRows.filter(i => i.carID !== row.carID);
            break;
            
    		case 'descuento':
    			this.selectDescuento.push(row);
                this.dataRows = this.dataRows.filter(i => i.descID !== row.descID);
    		break;
    	}
  	}
}



import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, ModalController} from '@ionic/angular';
import { GlobalService } from './../../../../config/services/global.service';
import { UnidadMedidaList } from './../../../../models/inventario.models';
//import { SelRol, SelMenuPermisos } from './../../../../models/global.models';

@Component({
  selector: 'app-modal-unidad-medida',
  templateUrl: './modal-unidad-medida.component.html',
  styleUrls: ['./modal-unidad-medida.component.scss'],
})
export class ModalUnidadMedidaComponent implements OnInit {
	@ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
    @ViewChild('unidadMedidaSearchBar')unidadMedidaSearchBar;
    
    @Input() select: string;
    @Input() tipo: string;
    @Input() unidadMedidaID: string;
    @Input() famSelect: [];

    public unidadMedidaList: UnidadMedidaList;
    //public rol:         SelRol;
    //public permisos:    SelMenuPermisos;

    public search_unidadMedida:   string  = '';
    public loadUnidadMedida:      boolean = true;
    public loadUnidadMedidaIni:   boolean = false;
    public from: any;
    public subscriptionUnidadMedida:any;
    public itemSelect = [];

  	constructor( 
  		private globalService: GlobalService,
        private router: Router,
        public  modalController: ModalController
  	) {
        this.unidadMedidaList = {list: [], page: 0, pages: 0, iTotal: 0, search: ''}
    }

  	ngOnInit() {
  		if(this.famSelect !== undefined){
            this.itemSelect = this.famSelect;
        }
        if(this.globalService.selects.unidMed.length <= 0){
            this.list('ini', null, 'Cargando...');
        }else{
            this.unidadMedidaList.list = this.globalService.selects.unidMed;
        }
        
        this.subscriptionUnidadMedida = this.globalService.changesUnidadMedidaList.subscribe(unidadMedida => { 
            this.subscribeSave(unidadMedida)
        })
        this.focusSearch();
  	}

    ngOnDestroy() {
        this.subscriptionUnidadMedida.unsubscribe();
    }

    subscribeSave(unidadMedida) {
        if(unidadMedida != null) {
            if(unidadMedida.tipo == 'add') {
                this.unidadMedidaList.list.unshift(unidadMedida.unidadMedidaList);
            }else{
                for (var i = this.unidadMedidaList.list.length - 1; i >= 0; i--) {
                    if(this.unidadMedidaList.list[i].id == unidadMedida.idLast){
                    	if(unidadMedida.tipo == 'edit') {
                            this.unidadMedidaList.list[i] = unidadMedida.unidadMedidaList;
                        }else{
                            this.unidadMedidaList.list.splice(i,1);
                        }
                        break
                    }
                }
            }
        }
    }

    unidadMedidaSearch(ev) {
        this.unidadMedidaList.page = 0;
        this.unidadMedidaList.list = [];
        this.list('ini', null, 'Buscando...');
    }

    unidadMedidaClearSearch(ev) {
    	if(this.unidadMedidaList.search != ''){
	        this.unidadMedidaList.page   = 0;
	        this.unidadMedidaList.search = '';
	        this.unidadMedidaList.list   = [];
	    }
    }

    focusSearch() {
        setTimeout(() =>{
        	this.unidadMedidaSearchBar.setFocus();
        },400);
    }

  	goInfinite(ev) {
        this.list('scroll', ev, '');
    }

  	async list(from, ev, loadinSMS) {
  		const token       = await this.globalService.getToken();
        const entidad:any = await this.globalService.entidadDefault();

        this.unidadMedidaList.page      = this.unidadMedidaList.page+1;
        let params                 = {};
            params['take']         = 500;
            params['page']         = this.unidadMedidaList.page;
            params['search']       = this.unidadMedidaList.search;
            params['appID']        = this.globalService.appID;
            params['sedeID']       = entidad.sedeID;
            params['unidadMedidaID']  = null;
            params['familiaID']    = null;
        if(loadinSMS != ''){
        	//await this.globalService.showLoading('Cargando...');
        }
        this.loadUnidadMedida = true;
        this.globalService.requestPost('invent/unidadMedida', params, 'jwt', token).subscribe(
            data => {
                if(data.validation == 'ok'){
                    let disabled = true;
                    if(data.list.length > 0){ 
                        this.globalService.selects.unidMed = data.list;
                        if(this.select == 'single') {
                            this.unidadMedidaList.list = data.list;
                        }else{
                            if(this.itemSelect.length > 0){
                                for (var i = 0; i < data.list.length; i++) {
                                    let noExiste = false;
                                    for (var j = this.itemSelect.length - 1; j >= 0; j--) {
                                        if(data.list[i].id == this.itemSelect[j].id){
                                            noExiste = false;
                                            break;
                                        }else{
                                            noExiste = true;
                                        }
                                    }
                                    if(noExiste){
                                        this.unidadMedidaList.list.push( data.list[i] );
                                    }
                                }
                            }else{
                                this.unidadMedidaList.list = data.list;
                            }
                        }
                        disabled = false;
                    }
                    if(ev != null){
                        ev.target.disabled = disabled;
                        ev.target.complete();
                    }
                    
                    this.loadUnidadMedida    = false;
                    this.loadUnidadMedidaIni = true;
                    if(loadinSMS != ''){
                    	//this.globalService.ionViewDidLoad();
                    }
                }else{
                    this.globalService.failRequestHttp(data);
                }
            },
            error => {
                this.globalService.errorRequestHttp(error)
            }
        );
  	}

  	async newUnidadMedida(ev) {
  		this.router.navigate(['/unidadMedida-addedit', {'tipo': 'add'}])
  		setTimeout(() =>{
            this.modalController.dismiss({'unidadMedidaInfo': null})
        },400);
    }

    async unidadMedidaSelect(item, ev) {
        
        if(this.select == 'single') {
            this.modalController.dismiss({
                'unidadMedidaInfo': item
            });
        }else if(this.select == 'singleMultiple') {
            this.itemSelect.push(item);
            this.modalController.dismiss({
                'unidadMedidaInfo': item
            });
        }else{
            this.itemSelect.push(item);
            for (var i = this.unidadMedidaList.list.length - 1; i >= 0; i--) {
                if(this.unidadMedidaList.list[i].id == item.id){
                    this.unidadMedidaList.list.splice(i,1);
                }
            }
        }
    }

    async unidadMedidaEliminar(item, ev) {
        for (var i = this.itemSelect.length - 1; i >= 0; i--) {
            if(this.itemSelect[i].id == item.id){
                this.itemSelect.splice(i,1);
            }
        }
        this.unidadMedidaList.list.push(item);
    }

    async dismissModal(ev) {
        this.modalController.dismiss({
            'unidadMedidaInfo': null
        })
    }
}





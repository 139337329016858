import { Component, OnInit, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MenuController, NavController } from '@ionic/angular';

import { GlobalService } from './config/services/global.service';
import { AuthService } from './config/services/auth.service';
import { SocketService } from './config/services/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private entidad: any;
  private changesBadges: any;
  private authObser: any;
  private connetObser: any;
  private postObser: any;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private route: ActivatedRoute,
    public globalService: GlobalService,
    public authService: AuthService,
    private socketService: SocketService,
    private menu: MenuController,
    private navCtrl: NavController
  ) {
    this.initializeApp();
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.authObser.unsubscribe();
    // -- this.connetObser.unsubscribe();
    // -- this.changesBadges.unsubscribe();
  }

  logoutCom() {
    this.globalService.logout();
  }

  initializeApp() {
    // -- this.notificationsService.init();
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      if (this.platform.is('cordova')) {
        // -- this.notificationsService.natPushNotifications();
      }
    });

    this.authObser = this.globalService.authenticationState.subscribe((state) => {
      if (state === true) {
        // -- no se estan usando por ahora
        // -- this.socketService.connect();
        // -- this.socketConnect();
        setTimeout(() => {
          this.globalService.iniMenu(null);
        }, 300);
      }
    });
  }

  gomenu(item, ev) {
    let params = {};
    if (item.men_params != null && item.men_params != '') {
      const params2: string = item.men_params;
      params = JSON.parse(params2);
    }
    if (item.men_url == '/login') {
      this.authObser.unsubscribe();
      // -- this.changesBadges.unsubscribe();
      this.logoutCom();
    } else {
      this.router.navigate([item.men_url, params]);
    }
    this.menu.close();
    ev.stopPropagation();
  }

  // -- no se estan usando por ahora
  async socketConnect() {
    // -- await this.globalService.iniMenu(null); lo comente para ponerlo directo
    // -- cada vez que se conecta socketsServer ejecuta este Behavior
    this.connetObser = this.socketService.reconectServer.subscribe((status) => {
      if (status) {
        this.socketBadge();
      }
    });
  }
  // -- no se estan usando por ahora
  async socketBadge() {
    const entidad: any = await this.globalService.entidadDefault();
    await this.globalService.userLogin().then((user) => {
      const userLog: any = user;
      const data = { tipo: 'join', sedeID: entidad.sedeID, from: 'menu', userID: userLog.id };
      this.socketService.sendSocket('ventGeneral', data);
    });
    this.changesBadges = this.socketService.getSocket('ventGeneral').subscribe((data: any) => {
      if (data != null) {
        this.loadBadge();
      }
    });
  }
  // -- no se estan usando por ahora
  async loadBadge() {
    const entidad: any = await this.globalService.entidadDefault();
    const token: any = await this.globalService.getToken();
    const rol: any = await this.globalService.getRol();
    await this.globalService.userLogin().then((user) => {
      const rolm: any = rol[0].rol_rolm_id;
      const userLog: any = user;
      const params = {
        appID: this.globalService.appID,
        sedeID: entidad.sedeID,
        from: 'menu',
        rolmID: rolm,
        userID: userLog.id,
      };
      this.postObser = this.globalService
        .requestPost('notifi/getBadge', params, 'jwt', token)
        .subscribe(
          (data) => {
            if (data.validation == 'ok') {
              this.globalService.appBadges = data.badges;
              for (let i = this.globalService.appBadges.length - 1; i >= 0; i--) {
                for (let j = this.globalService.appPages.length - 1; j >= 0; j--) {
                  if (
                    this.globalService.appPages[j].menMID == this.globalService.appBadges[i].menu
                  ) {
                    this.globalService.appPages[j].badge = this.globalService.appBadges[i].badge;
                    break;
                  }
                }
              }
            } else {
              this.globalService.failRequestHttp(data);
            }
          },
          (error) => {
            this.globalService.errorRequestHttp(error);
          }
        );
    });
  }
}

import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Platform } from '@ionic/angular';
import { GlobalService } from './../services/global.service';
import { SocketService } from './../services/socket.service';
import { MenuController, NavController } from '@ionic/angular';

const tokenkey = 'smartge_empresarial_token';
 
@Injectable({
    providedIn: 'root'
})
export class AuthService {
	private token: any = null;
    rejectionReasons = ['token_not_provided', 'token_expired', 'token_absent', 'token_invalid', 'Usuario o contraseña no son validos, intentalo nuevamente.'];
    constructor(
        private plt: Platform,
        private globalService: GlobalService,
        private socketService: SocketService,
        private menu: MenuController,
        private navCtrl: NavController,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) { 
        this.checkToken();       
    }

    async checkToken() {
        const token = await this.globalService.getToken();
        if (token) {
            this.globalService.authenticationState.next(true);
        }else{
            this.globalService.authenticationState.next(false);
        }
    }
 
    async login(credentials) {
        await this.globalService.showLoading('Iniciando sesión...');
        return new Promise((resolve, reject) => {
            this.globalService.requestPost('auth/authenticate', JSON.stringify(credentials), 'noauth', '').subscribe(
                data => {
                    if(data.validation == 'ok'){
                        this.globalService.ionViewDidLoad();
                        setTimeout(() =>{
                            this.authSuccess(data);
                            resolve(true);
                        },400)
                    }else{
                        this.globalService.failRequestHttp(data);
                        resolve(false);
                    }
                },
                error => {
                    this.globalService.errorRequestHttp(error)
                }
            );
        });
    }

    authSuccess(data) {
        let user    = data.userInfo;
        let entidad = data.entidadInfo;

        this.globalService.setString(tokenkey, data.result.access_token);
        this.globalService.setString('smartge_empresarial_user', user);
        this.globalService.setString('smartge_empresarial_entidad', entidad);

        this.globalService.token = data.result.access_token;
        this.globalService.userInfo.next(data.userInfo);
        this.globalService.entidadInfo.next(data.entidadInfo);

        this.menu.enable(true, 'main');
        this.router.navigate(['/dashboard', {}])
        setTimeout(() =>{
            this.globalService.authenticationState.next(true);
        })
    }
    
}


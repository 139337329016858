import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, ModalController} from '@ionic/angular';
import { GlobalService } from './../../../../config/services/global.service';
import { TerceroList, RowTercero } from '../../../../models/tercero.models';

@Component({
    selector: 'app-modal-tercero',
    templateUrl: './modal-tercero.component.html',
    styleUrls: ['./modal-tercero.component.scss'],
})
export class ModalTerceroComponent implements OnInit {
	@ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
    @ViewChild('terceroSearchBar', null)terceroSearchBar;
    
    @Input() terceroTipo: string;
    @Input() select: string;
    @Input() tipo: string;
    @Input() selected: RowTercero;


    public terceroList:    TerceroList;
	  public search_tercero: string  = '';
	  public loadTercero:    boolean = true;
    public loadTerceroIni: boolean = false;
    public showSearch:    boolean = false;
    public from: any;
    public itemSelect = [];
    public selectAll: any;

  	constructor( 
  		public globalService: GlobalService,
  		private router: Router,
  		public  modalController: ModalController
  	) {
        this.terceroList = {list: [], page: 0, pages: 0, iTotal: 0, search: ''}
    }

  	ngOnInit() {
  		switch (this.terceroTipo) {
  			case "funcionario":
  				this.selectAll = this.globalService.selects.funcionario;
  			break;
  			case "proveedor":
  				this.selectAll = this.globalService.selects.proveedor;
  			break;
  			case "cliente":
  				this.selectAll = this.globalService.selects.cliente;
  			break;
  			default:
  				this.selectAll = this.globalService.selects.tercero;
  			break;
  		}
  		if(this.selectAll.length <= 0){
        	this.list('ini', null, 'Cargando...');
  		}else{
            this.terceroList.page = 1;
  			this.terceroList.list = this.selectAll;
            if(this.selected !== undefined || this.selected != null)
  			    this.terceroList.list = this.terceroList.list.filter(i => i.id !== this.selected.id);
  		}
        this.focusSearch();
  	}

    searchToggle() {
        if (this.showSearch) {
          this.showSearch = false;
          if (this.terceroList.search !== '') {
            this.itemClearSearch(null);
            this.loadTercero = true;
            this.list('ini', null, 'Cargando...');
          }
        } else {
          this.showSearch = true;
          this.focusSearch();
        }
    }
    
    itemSearch(ev) {
        this.terceroList.page = 0;
        this.terceroList.list = [];
        this.list('ini', null, 'Buscando...');
    }
    
    itemClearSearch(ev) {
        if (this.terceroList.search !== '') {
          this.terceroList.page = 0;
          this.terceroList.search = '';
          this.terceroList.list = [];
        }
    }
    
    focusSearch() {
        setTimeout(() =>{
        	this.terceroSearchBar.setFocus();
        },400);
    }

  	goInfinite(ev) {
        this.list('scroll', ev, '');
    }


  	async list(from, ev, loadinSMS) {
  		const token       = await this.globalService.getToken();
        const entidad:any = await this.globalService.entidadDefault();

        this.terceroList.page      = this.terceroList.page+1;
        let params                 = {};
            params['take']         = 50;
            params['page']         = this.terceroList.page;
            params['search']       = this.terceroList.search;
            params['appID']        = this.globalService.appID;
            params['sedeID']       = entidad.sedeID;
            params['terceroID']    = null;
            params['from']         = this.terceroTipo;
        this.loadTercero = true;
        this.globalService.requestPost('tercero/tercero', params, 'jwt', token).subscribe(
            data => {
                if(data.validation == 'ok'){
                    let disabled = true;
                    if(data.list.length > 0){ 
                    	switch (this.terceroTipo) {
				  			case "funcionario":
                                  this.globalService.selects.funcionario = data.list;
				  			break;
				  			case "proveedor":
				  				this.globalService.selects.proveedor = data.list;
				  			break;
				  			case "cliente":
				  				this.globalService.selects.cliente = data.list;
				  			break;
				  			default:
				  				this.globalService.selects.tercero = data.list;
				  			break;
				  		}

                    	//-- para que no aparezca la tercero seleccionada
                    	if(this.selected !== undefined){
                        	data.list =  data.list.filter(i => i.id !== this.selected.id);
                        }

                        for (var i = 0; i < data.list.length; i++) {
                            this.terceroList.list.push( data.list[i] );
                        }
                        //this.terceroList.list = data.list;
                        disabled = false;
                    }

                    if(ev != null){
                        ev.target.disabled = disabled;
                        ev.target.complete();
                    }
                    
                    this.loadTercero    = false;
                    this.loadTerceroIni = true;
                    if(this.showSearch){
                    	this.focusSearch();
                    }
                }else{
                    this.globalService.failRequestHttp(data);
                }
            },
            error => {
                this.globalService.errorRequestHttp(error)
            }
        );
  	}

  	async terceroSelect(item, ev) {
        if(this.select == 'single') {
            this.modalController.dismiss({
                'terceroInfo': item
            });
        }else if(this.select == 'singleMultiple') {
            this.itemSelect.push(item);
            this.modalController.dismiss({
                'terceroInfo': item
            });
        }
    }

  	async dismissModal(ev) {
        this.modalController.dismiss({
            'terceroInfo': null
        })
    }
}






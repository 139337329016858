import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, ModalController} from '@ionic/angular';
import { GlobalService } from './../../../../config/services/global.service';
import { SelPlazo } from '../../../../models/global.models';

@Component({
  	selector: 'app-modal-plazo',
  	templateUrl: './modal-plazo.component.html',
  	styleUrls: ['./modal-plazo.component.scss'],
})
export class ModalPlazoComponent implements OnInit {
    @ViewChild('plazoSearchBar')plazoSearchBar;
    
    @Input() select: string;
    @Input() tipo: string;

    public plazoList:any;    //SelPlazo;
	  //public plazoSearch: string  = '';
	  public loadPlazo:    boolean = true;
    public loadPlazoIni: boolean = false;
    public showSearch:    boolean = false;
    public from: any;
    public itemSelect = [];

  	constructor( 
  		private globalService: GlobalService,
  		private router: Router,
  		public  modalController: ModalController
  	) {
  		this.plazoList = {list: [], page: 0, pages: 0, iTotal: 0, search: ''}
  	}

  	ngOnInit() {
  		if(this.globalService.selects.plazo.length <= 0){
        	this.list('ini', null, 'Cargando...');
  		}else{
  			this.plazoList.list = this.globalService.selects.plazo;
  		}
        this.focusSearch();
  	}
    
    focusSearch() {
        setTimeout(() =>{
        	this.plazoSearchBar.setFocus();
        },400);
    }

  	async list(from, ev, loadinSMS) {
  		const token       = await this.globalService.getToken();
        const entidad:any = await this.globalService.entidadDefault();

        this.plazoList.page      = this.plazoList.page+1;
        let params                 = {};
            params['take']         = 500;
            params['page']         = this.plazoList.page;
            params['search']       = this.plazoList.search;
            params['appID']        = this.globalService.appID;
            params['sedeID']       = entidad.sedeID;
            params['plazoID']      = null;
        this.loadPlazo = true;
        this.globalService.requestPost('global/plazo', params, 'jwt', token).subscribe(
            data => {
                if(data.validation == 'ok'){
                    let disabled = true;
                    if(data.list.length > 0){ 
                    	this.globalService.selects.plazo = data.list;
                        this.plazoList.list = data.list;
                        disabled = false;
                    }
                    this.loadPlazo    = false;
                    this.loadPlazoIni = true;
                    if(this.showSearch){
                    	this.focusSearch();
                    }
                }else{
                    this.globalService.failRequestHttp(data);
                }
            },
            error => {
                this.globalService.errorRequestHttp(error)
            }
        );
  	}

  	async plazoSelect(item, ev) {
        if(this.select == 'single') {
            this.modalController.dismiss({
                'plazoInfo': item
            });
        }else if(this.select == 'singleMultiple') {
            this.itemSelect.push(item);
            this.modalController.dismiss({
                'plazoInfo': item
            });
        }
    }

  	async dismissModal(ev) {
        this.modalController.dismiss({
            'plazoInfo': null
        })
    }
}




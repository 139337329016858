import { Injectable, Inject } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './../services/auth.service';
import { GlobalService } from './../services/global.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        private authService: AuthService,
        public globalService: GlobalService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let stateLog: any = null;
        return this.globalService.getToken().then((token: any) => {
            let state = false;
            if (token) {
                state = true;
            }else{
                this.globalService.authenticationState.next(false);
                this.globalService.logout();
            }
            return state;
        })
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.globalService.getToken().then((token: any) => {
            let state = false;
            if (token) {
                state = true;
            }else{
                this.globalService.authenticationState.next(false);
                this.globalService.logout();
            }
            return state;
        })
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.globalService.getToken().then((token: any) => {
            let state = false;
            if (token) {
                state = true;
            }else{
                this.globalService.authenticationState.next(false);
                this.globalService.logout();
            }
            return state;
        })
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment'


@Pipe({ name : "FormatDate" })
export class FormatDate implements PipeTransform {
    transform(value: string | Date, ...args) {
        return moment(value).format('DD/MM/YYYY')
    }
}

@Pipe({ name : "FormatDateTime" })
export class FormatDateTime implements PipeTransform {
    transform(value: string | Date, ...args) {
        return moment(value).format('DD/MM/YYYY hh:mm:a')
    }
}

@Pipe({ name: 'ShortName', })
export class ShortName implements PipeTransform {
	transform(name: string): any {
  		if(name != null) {
            var nameShort = name.split(" ");
            if(nameShort.length == 2){
                return nameShort[0]+' '+nameShort[nameShort.length-1].substring(0, 1)+'.';
            }else{
                if(nameShort.length > 2){
                    return nameShort[0]+' '+nameShort[1]+' '+nameShort[nameShort.length-1].substring(0, 1)+'.';
                }else{
                    return name;
                }
            }
        }else{
            return name;
        }
  	}
}

@Pipe({ name: 'Numberformat', })
export class Numberformat implements PipeTransform {
	transform(numero: any): any {
  		if(numero != null) {
            numero = parseFloat(numero)
        	let n = 0, x=3, s='.', c=',';
        	var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
	        num = numero.toFixed(Math.max(0, ~~n));
	    	return  (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
        }else{
            return numero;
        }
  	}
}

@Pipe({ name: 'Search'})
export class Search implements PipeTransform {
    transform(value: any, args?: any): any {

        if(!value)return null;
        if(!args)return value;

        args = args.toLowerCase();

        return value.filter(function(item){
            return JSON.stringify(item).toLowerCase().includes(args);
        });
    }
}

@Pipe({ name : "RemoveSpaces" })
export class RemoveSpaces{
    transform(value){
        return value.replace(/ /g, "");
    }
}

@Pipe({name: 'Capitalize'})
export class Capitalize implements PipeTransform {
    transform(value: string): any {
        if (!value) return value;

        return value.replace(/\w\S"exto con las comillas era un asterisco"/g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
}

@Pipe({ name: 'LowerCase', })
export class LowerCase implements PipeTransform {
    transform(value: string, ...args) {
        return value.toLowerCase();
    }
}

@Pipe({ name : "Tel" })
export class Tel implements PipeTransform {
    transform(tel: string): any {
        if (!tel) { return ''; }

        var value = tel.replace(/\D/g,'');
        if (value.match(/[^0-9]/)) {
            return tel;
        }

        var country, city, number, numberFormat;
        switch (value.length) {
            case 11: // +CPPP####### -> CCC (PP) ###-####
                if(value[0] == '1'){
                    country = value[0];
                    city    = value.slice(1, 4);
                    number  = value.slice(4);
                    number  = number.slice(0, 3) + ' ' + number.slice(3);
                    numberFormat = ("+"+country + " " + city + " " + number).trim();
                }else{
                    numberFormat = ("+"+value).trim();
                }
            break;
            case 12: // +CCCPP####### -> CCC (PP) ###-####
                country = value.slice(0, 2);
                city    = value.slice(2, 5);
                number  = value.slice(5);
                number  = number.slice(0, 3) + ' ' + number.slice(3);
                numberFormat = ("+"+country + " " + city + " " + number).trim();
            break;
        }
        let lengthValue = value.length;
        if (lengthValue < 10 || lengthValue > 12) {
            return '+'+tel;
        }else{
            return numberFormat;
        }

        /*
        switch (value.length) {
            case 10: // +1PPP####### -> C (PPP) ###-####
                country = 1;
                city = value.slice(0, 3);
                number = value.slice(3);
            break;
            case 11: // +CPPP####### -> CCC (PP) ###-####
                country = value[0];
                city = value.slice(1, 4);
                number = value.slice(4);
            break;
            case 12: // +CCCPP####### -> CCC (PP) ###-####
                country = value.slice(0, 2);
                city = value.slice(2, 5);
                number = value.slice(5);
            break;
        }

        let lengthValue = value.length;
        if (lengthValue < 10 || lengthValue > 12) {
            return tel;
        }

        number = number.slice(0, 3) + '-' + number.slice(3);
        return ("+"+country + " (" + city + ") " + number).trim();
        */
    }
}

@Pipe({ name : "TotalTime" })
export class TotalTime implements PipeTransform {
    transform(startTime: any, endTime: any): any {

        //let start = moment.utc(startTime, "HH:mm");
        //let end   = moment.utc(endTime, "HH:mm");

        let start = moment.utc(startTime, "h:mm a");
        let end   = moment.utc(endTime, "h:mm a");

        // account for crossing over to midnight the next day
        //if (end.isBefore(start)) end.add(1, 'day');

        // calculate the duration
        let pref = '';
        if(start <= end){
           var d = moment.duration(end.diff(start));
        }else{
            var d = moment.duration(start.diff(end));
            pref = '-';
        }

        // subtract the lunch break
        // d.subtract(30, 'minutes');

        // format a string result
        let h = moment.utc(+d).format('H');
        let m = moment.utc(+d).format('mm');

        return pref+h+' hr '+m+ ' min';
    }
}

@Pipe({ name : "Sizemb" })
export class Sizemb implements PipeTransform {
    transform(size: any): any {
        return Number(((size / 1024) / 1024)).toFixed(2);
    }
}

@Pipe({name: 'Linkify'})
export class Linkify implements PipeTransform {
    transform(link: string): string {
        return this.linkify(link);
    }

    private linkify(plainText): string{
        let replacedText;
        let replacePattern1;
        let replacePattern2;
        let replacePattern3;

        //URLs starting with http://, https://, or ftp://
        replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        replacedText = plainText.replace(replacePattern1, '$1');

        /*
        //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
        replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
        replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

        //Change email addresses to mailto:: links.
        replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
        replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');
        */
        return replacedText;
    }
}

@Pipe({name: 'EmbebedUrl'})
export class EmbebedUrl implements PipeTransform {
    transform(url: string): boolean {
        return this.embebedUrl(url);
    }

    private embebedUrl(plainText): boolean{
        let replacedText: boolean;

        if( plainText.includes("https://docs.google.com") == true ||
            plainText.includes("https://www.google.com/maps") == true ||
            plainText.includes("https://sites.google.com/") == true ||
            plainText.includes("https://fusiontables.google.com/") == true){
                replacedText = true;
        }else{
            replacedText = false;
        }
        return replacedText;
    }
}

@Pipe({name: 'StrengthPass'})
export class StrengthPass implements PipeTransform {
    transform(pass: string): any {

        var grep = function(arr, callback) {
            var newArr = [],
                len = arr.length,
                i;
            for (i = 0; i < len; i++) {
                var e = arr[i];
                if (callback(e)) {
                    newArr.push(e);
                }
            }
            return newArr;
        }

        var strength = {
            colors: ['#a8c', '#F90', '#FF0', '#1E8E3E', '#1E8E3E'],
            mesureStrength: function (p) {

                var _force = 0;                    
                var _regex = /[$-/:-?{-~!"^_`\[\]]/g;
                                      
                var _lowerLetters = /[a-z]+/.test(p);                    
                var _upperLetters = /[A-Z]+/.test(p);
                var _numbers = /[0-9]+/.test(p);
                var _symbols = _regex.test(p);
                                      
                var _flags = [_lowerLetters, _upperLetters, _numbers, _symbols];                    
                var _passedMatches = grep(_flags, function (el) { return el === true; }).length;                                          
                
                _force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
                _force += _passedMatches * 10;
                    
                // penality (short password)
                _force = (p.length <= 6) ? Math.min(_force, 10) : _force;                                      
                
                // penality (poor variety of characters)
                _force = (_passedMatches == 1) ? Math.min(_force, 10) : _force;
                _force = (_passedMatches == 2) ? Math.min(_force, 20) : _force;
                _force = (_passedMatches == 3) ? Math.min(_force, 40) : _force;
                
                return _force;

            },
            getColor: function (s) {

                var idx = 0;
                var bmx = 0;
                var word = '';
                if (s <= 10) { idx = 0; bmx = 0;word = 'VERY WEAK (A strong Password is required).';} //'Very weak';}
                else if (s <= 20) { idx = 25; bmx = 1;word = 'WEAK (A strong Password is required).';} //'Weak';}
                else if (s <= 30) { idx = 50; bmx = 2;word = 'GOOD (A strong Password is required).';} //'Good';}
                else if (s <= 40) { idx = 75; bmx = 3;word = 'STRONG';}
                else{ idx = 100;bmx = 4;word = 'VERY STRONG';}

                return { idx: idx, col: this.colors[bmx], word: word };

            }
        };
        let result = strength.getColor(strength.mesureStrength(pass));
        return result;
    }
}


import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';

import { GlobalService } from '../../../../config/services/global.service';
import { ItemList } from '../../../../models/inventario.models';

@Component({
  selector: 'app-modal-item',
  templateUrl: './modal-item.component.html',
  styleUrls: ['./modal-item.component.scss'],
})
export class ModalItemComponent implements OnInit, OnDestroy {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild('itemSearchBar') itemSearchBar;

  @Input() select: string;
  @Input() tipo: string;
  @Input() itemID: number;
  @Input() bodegaID: number;
  @Input() precioID: number;
  @Input() itemSelPrev: [];
  @Input() from: string;
  @Input() tipoItem?: number;

  public itemList: ItemList;
  public loadItem = true;
  public loadItemIni = false;
  public subscriptionItem: any;
  public itemSelect = [];
  public showSearch = false;

  constructor(
    public globalService: GlobalService,
    private router: Router,
    public modalController: ModalController
  ) {
    this.itemList = { list: [], page: 0, pages: 0, iTotal: 0, search: '', searchBC: '' };
  }

  ngOnInit() {
    if (this.itemSelPrev !== null) {
      this.itemSelect = this.itemSelPrev;
    }

    if (this.globalService.selects.item.length <= 0) {
      this.list('ini', null, 'Cargando...');
    } else {
      this.asignList(this.globalService.selects.item);
    }
    this.subscriptionItem = this.globalService.changesItemList.subscribe((item) => {
      this.subscribeSave(item);
    });
    this.focusSearch();
  }

  ngOnDestroy() {
    this.subscriptionItem.unsubscribe();
  }

  subscribeSave(item) {
    if (item !== null) {
      if (item.tipo === 'add') {
        this.itemList.list.unshift(item.itemList);
      } else {
        for (let i = this.itemList.list.length - 1; i >= 0; i = i - 1) {
          if (this.itemList.list[i].id === item.idLast) {
            if (item.tipo === 'edit') {
              this.itemList.list[i] = item.itemList;
            } else {
              this.itemList.list.splice(i, 1);
            }
            break;
          }
        }
      }
    }
  }

  searchToggle() {
    if (this.showSearch) {
      this.showSearch = false;
      if (this.itemList.search !== '') {
        this.itemClearSearch(null);
        this.loadItem = true;
        this.list('ini', null, 'Cargando...');
      }
    } else {
      this.showSearch = true;
      this.focusSearch();
    }
  }

  itemSearch(ev) {
    this.itemList.page = 0;
    this.itemList.list = [];
    this.list('ini', null, 'Buscando...');
  }

  itemClearSearch(ev) {
    if (this.itemList.search !== '') {
      this.itemList.page = 0;
      this.itemList.search = '';
      this.itemList.list = [];
    }
  }

  focusSearch() {
    setTimeout(() => {
      this.itemSearchBar.setFocus();
    }, 400);
  }

  goInfinite(ev) {
    if (this.globalService.selects.item.length <= 0) {
      this.list('scroll', ev, '');
    } else {
      ev.target.disabled = true;
      ev.target.complete();
    }
  }

  asignList(list: any) {
    this.itemList.page = 1;
    this.loadItem = false;
    if (this.itemSelect.length > 0) {
      // -- cambiar por maper and find
      for (let i = 0; i < list.length; i = i + 1) {
        let noExiste = false;
        for (let j = this.itemSelect.length - 1; j >= 0; j = j - 1) {
          if (list[i].id === this.itemSelect[j].itemID) {
            noExiste = false;
            break;
          } else {
            noExiste = true;
          }
        }
        if (noExiste) {
          this.itemList.list.push(list[i]);
        }
      }
      return;
    }

    for (let i = 0; i < list.length; i = i + 1) {
      this.itemList.list.push(list[i]);
    }
  }

  async list(from, ev, loadinSMS) {
    const token = await this.globalService.getToken();
    const entidad: any = await this.globalService.entidadDefault();

    this.itemList.page = this.itemList.page + 1;
    const params = {};
    params['take'] = 200;
    params['page'] = this.itemList.page;
    params['search'] = this.itemList.search;
    params['appID'] = this.globalService.appID;
    params['sedeID'] = entidad.sedeID;
    params['itemID'] = null;
    params['bodegaID'] = this.bodegaID;
    params['precioID'] = this.precioID;
    params['from'] = this.from;
    params['tipoItem'] = this.tipoItem;
    if (loadinSMS !== '') {
      // -- await this.globalService.showLoading('Cargando...');
    }
    this.loadItem = true;
    this.globalService.requestPost('invent/item', params, 'jwt', token).subscribe(
      (data) => {
        if (data.validation === 'ok') {
          let disabled = true;
          if (data.list.length > 0) {
            this.globalService.selects.item = data.list;
            this.asignList(data.list);
            disabled = false;
          }
          if (ev !== null) {
            ev.target.disabled = disabled;
            ev.target.complete();
          }

          this.loadItem = false;
          this.loadItemIni = true;
          if (loadinSMS !== '') {
            // -- this.globalService.ionViewDidLoad();
          }
        } else {
          this.globalService.failRequestHttp(data);
        }
      },
      (error) => {
        this.globalService.errorRequestHttp(error);
      }
    );
  }

  async newItem(ev) {
    this.router.navigate(['/item-addedit', { tipo: 'add' }]);
    setTimeout(() => {
      this.modalController.dismiss({ itemInfo: null });
    }, 400);
  }

  async itemSelected(item, ev) {
    if (this.select === 'single') {
      this.modalController.dismiss({
        itemInfo: item,
      });
    } else if (this.select === 'singleMultiple') {
      this.itemSelect.push(item);
      this.modalController.dismiss({
        itemInfo: item,
      });
    } else {
      this.itemSelect.push(item);
      for (let i = this.itemList.list.length - 1; i >= 0; i = i - 1) {
        if (this.itemList.list[i].id === item.id) {
          this.itemList.list.splice(i, 1);
        }
      }
    }
  }

  async itemEliminar(item, ev) {
    for (let i = this.itemSelect.length - 1; i >= 0; i = i - 1) {
      if (this.itemSelect[i].id === item.id) {
        this.itemSelect.splice(i, 1);
      }
    }
    this.itemList.list.push(item);
  }

  async dismissModal(ev) {
    this.modalController.dismiss({
      itemInfo: null,
    });
  }
}

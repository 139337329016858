import { Injectable, NgZone } from '@angular/core';
import { Observable, of, throwError, Subject, BehaviorSubject } from 'rxjs';
import { catchError, tap, map, filter } from 'rxjs/operators';
import * as io from 'socket.io-client';



@Injectable({
  providedIn: 'root'
})
export class SocketService {
    socket: any;
    statusLoad: string;
    public  reconectCount  = 0;
    public  socketUrlDev   = 'http://localhost';
    public  socketUrlProd  = 'https://smartge.co:8443';
    public  socketUrl      = this.socketUrlProd;
    public  reconectServer = new BehaviorSubject(true);
    
    constructor() {}

    public connect() {
        this.socket = io(this.socketUrl, {port: 8443, secure: true, autoConnect:true, reconnection:true, transports: ['xhr-polling', 'websocket', 'polling']});

        let thisApp = this;
        this.socket.on('connect', function (socket) {
            console.log('connected to server socket');
        }); 

        this.socket.on("reconnect", function(socket) {
            console.log('reconnecting to server socket');
            thisApp.reconectSockets()
        });

        this.socket.on('error', (err) => {
            console.log('Error connecting to server', err);
        });
        
        this.socket.on('disconnect', function () {
            console.log('disconnected to server socket' );
        });
    }

    public reconectSockets(){
        setTimeout(() =>{
            this.reconectServer.next(true);
        },1000)
    }

    public sendSocket(socketName, data) {
        this.socket.emit(socketName, data);
    }

    public getSocket = (socketName) => {
        return Observable.create((observer) => {
            let zone = new NgZone({enableLongStackTrace: false});
            this.socket.on(socketName, (emit) => {
                zone.run(() => {
                    if(emit.data != null){
                        observer.next(emit);
                    }
                })
            });
            observer.next(null);
        });
    }


    public reloadList(from, data, user) {
        let action:   any = data.status;
        let fromEmit: any = data.from;
        let users:    any = data.users;
        let list:     any = data.list;
        let como:     any;
        
        for (var i = 0; i < users.length; i++) {
            if(users[i].userID == user.id){                
                break;
            }
        }
        let next: boolean = false;
        //-- logica a crear
        return next;
    }

}


import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from 'src/app/config/services/global.service';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ModalTerceroComponent } from '../../tercero/modal-tercero/modal-tercero.component';
import { RowTercero } from 'src/app/models/tercero.models';
import { NullTemplateVisitor } from '@angular/compiler';

@Component({
    selector: 'app-modal-pos-venta',
    templateUrl: './modal-pos-venta.component.html',
    styleUrls: ['./modal-pos-venta.component.scss'],
})
export class ModalPosVentaComponent implements OnInit {
    @Input() select: string;
    @Input() tipo: string;
    @Input() totalVenta: number;
    @Input() funcionario: RowTercero;

    // public funcionario: RowTercero;

    public posVentaInfo: any;
    public segmentTipo = 'debito';
    public selSegment: boolean = false;
    public selDiv: boolean = false;
    public selInfo: any;
    public tipoPagoList: any;

    public op1: any = 0;
    public op2: any = 0;
    public op3: any = 0;
    public efectivo: any = 0;
    public cambio: any = 0;
    public pCobrar: boolean = false;

    public tPagos = [];
    public numeros = [];

    public pagoForm: FormGroup = this.formBuilder.group({
        efeId: [null],
        tDeId: [null],
        tCrId: [null],
        traId: [null],
        conId: [null],
        cheId: [null],
        efectivo: [null],
        tDebito: [null],
        tCredito: [null],
        transferencia: [null],
        consignacion: [null],
        cheque: [null],
        tipo_pagoID: [null],
        venId: [null],
        vendedor: [null],
        nota: [null],
        total: [null],
        cambio: [null]
    });

    constructor(
        private globalService: GlobalService,
        public modalController: ModalController,
        public formBuilder: FormBuilder
    ) {
        this.tipoPagoList = {list: [], page: 0, pages: 0, iTotal: 0, search: ''}
     }

    ngOnInit() {
        this.pagoForm.controls.vendedor.setValue(this.funcionario.nombre);
        this.pagoForm.controls.venId.setValue(this.funcionario.id);
        this.op1 = this.totalVenta;
        this.op2 = (Math.round(this.op1 / 1000)) * 1000;

        if (this.op2 < this.op1) {
            this.op2 = this.op2 + 1000;
        }
        this.op3 = this.op2 + 1000
        Math.round(this.op1);
        this.cambio = this.op1 - (this.pagoForm.controls.efectivo.value +
            this.pagoForm.controls.tDebito.value +
            this.pagoForm.controls.tCredito.value +
            this.pagoForm.controls.transferencia.value+
            this.pagoForm.controls.consignacion.value +
            this.pagoForm.controls.cheque.value);
        if (this.cambio > 0) {
            this.pCobrar = true;
        } else {
            this.pCobrar = false;
        }
        this.list('ini', null, 'Cargando...');
    }

    async list(from, ev, loadinSMS) {
        const token = await this.globalService.getToken();
        const entidad: any = await this.globalService.entidadDefault();

        let params = {};
        params['take'] = 500;
        params['page'] = 1;
        params['search'] = '';
        params['appID'] = this.globalService.appID;
        params['sedeID'] = entidad.sedeID;
        params['tipoPagoID'] = null;
        params['clase'] = 'factura';
        params['cont'] = 1; //ingreso
        this.globalService.requestPost('global/tipoPago', params, 'jwt', token).subscribe(
            data => {
                if (data.validation == 'ok') {
                    if (data.list.length > 0) {
                        this.globalService.selects.tipoPago = data.list;
                        this.tipoPagoList.list = data.list;
                        let ctrCard: boolean = false;
                        let ctrtodos: boolean = false;
                        this.tipoPagoList.list.forEach(list => {
                            switch (list.tip_clase) {
                                case 'EFE':
                                    this.pagoForm.controls.efeId.setValue(list.id);
                                    break;
                                case 'CONS':
                                    this.pagoForm.controls.conId.setValue(list.id);
                                    break;
                                case 'TDEB':
                                    this.pagoForm.controls.tDeId.setValue(list.id);
                                    break;
                                case 'TCRED':
                                    this.pagoForm.controls.tCrId.setValue(list.id);
                                    break;
                                case 'TRANS':
                                    this.pagoForm.controls.traId.setValue(list.id);
                                    break;
                                case 'CHEQ':
                                    this.pagoForm.controls.cheId.setValue(list.id);
                                    break;
                            }
                            if (ctrtodos == false) {
                                ctrtodos = true;
                                this.tPagos.push({
                                    id: 0,
                                    tip_view: 1,
                                    tPago: 'ALL',
                                    title: 'TODOS',
                                    icon: 'cash',
                                    icon2: 'card'
                                });
                            }
                            if (list.tip_view == 0) {
                                if (ctrCard == false) {
                                    this.tPagos.push({
                                        id: 0,
                                        tip_view: 1,
                                        tPago: 'CARD',
                                        title: 'TARJETA',
                                        icon: list.tip_icon,
                                        icon2: ''
                                    });
                                }
                                ctrCard = true;
                            }else {
                                this.tPagos.push({
                                    id: list.id,
                                    tip_view: list.tip_view,
                                    tPago: list.tip_clase,
                                    title: list.tip_nombre,
                                    icon: list.tip_icon,
                                    icon2: ''
                                });
                            }
                        });
                    }
                }else {
                    this.globalService.failRequestHttp(data);
                }
            },
            error => {
                this.globalService.errorRequestHttp(error)
            }
        );
    }

    setValidators(tipo) {
        const efectivoControl = this.pagoForm.get('efectivo');
        const tDebitoControl = this.pagoForm.get('tDebito');
        const tCreditoControl = this.pagoForm.get('tCredito');
        const transferenciaControl = this.pagoForm.get('transferencia');
        const consignacionControl = this.pagoForm.get('consignacion');
        const chequeControl = this.pagoForm.get('cheque');
        switch (tipo) {
            case 'EFE':
                efectivoControl.setValidators([Validators.required]);
                tDebitoControl.setValidators(null);
                tCreditoControl.setValidators(null);
                transferenciaControl.setValidators(null);
                consignacionControl.setValidators(null);
                chequeControl.setValidators(null);
                this.pagoForm.controls.tipo_pagoID.setValue(this.pagoForm.controls.efeId.value);
                break;
            case 'CONS':
                consignacionControl.setValidators([Validators.required]);
                efectivoControl.setValidators(null);
                chequeControl.setValidators(null);
                tDebitoControl.setValidators(null);
                tCreditoControl.setValidators(null);
                transferenciaControl.setValidators(null);
                this.pagoForm.controls.tipo_pagoID.setValue(this.pagoForm.controls.conId.value);
                break;
            case 'CARD':
                if (this.segmentTipo == 'debito') {
                    efectivoControl.setValidators(null);
                    tDebitoControl.setValidators([Validators.required]);
                    tCreditoControl.setValidators(null);
                    transferenciaControl.setValidators(null);
                    consignacionControl.setValidators(null);
                    chequeControl.setValidators(null);
                    this.pagoForm.controls.tipo_pagoID.setValue(this.pagoForm.controls.tDeId.value);
                } else if (this.segmentTipo == 'credito') {
                    efectivoControl.setValidators(null);
                    tDebitoControl.setValidators(null);
                    tCreditoControl.setValidators([Validators.required]);
                    transferenciaControl.setValidators(null);
                    consignacionControl.setValidators(null);
                    chequeControl.setValidators(null);
                    this.pagoForm.controls.tipo_pagoID.setValue(this.pagoForm.controls.tCrId.value);
                }
                break;
            case 'TRANS':
                efectivoControl.setValidators(null);
                tDebitoControl.setValidators(null);
                tCreditoControl.setValidators(null);
                transferenciaControl.setValidators([Validators.required]);
                consignacionControl.setValidators(null);
                chequeControl.setValidators(null);
                this.pagoForm.controls.tipo_pagoID.setValue(this.pagoForm.controls.traId.value);
                break;
            case 'CHEQ':
                efectivoControl.setValidators(null);
                tDebitoControl.setValidators(null);
                tCreditoControl.setValidators(null);
                transferenciaControl.setValidators(null);
                consignacionControl.setValidators(null);
                chequeControl.setValidators([Validators.required]);
                this.pagoForm.controls.tipo_pagoID.setValue(this.pagoForm.controls.cheId.value);
            break;
            case 'ALL':
                efectivoControl.setValidators([Validators.required]);
                tDebitoControl.setValidators([Validators.required]);
                tCreditoControl.setValidators([Validators.required]);
                transferenciaControl.setValidators([Validators.required]);
                consignacionControl.setValidators([Validators.required]);
                chequeControl.setValidators([Validators.required]);
                this.pagoForm.controls.tipo_pagoID.setValue(0);
                break;
        }
        efectivoControl.updateValueAndValidity();
        tDebitoControl.updateValueAndValidity();
        tCreditoControl.updateValueAndValidity();
        transferenciaControl.updateValueAndValidity();
        consignacionControl.updateValueAndValidity();
        chequeControl.updateValueAndValidity();
    }

    segmentTipoChanged(ev: any) {
        this.segmentTipo = ev.detail.value;
        this.setValidators('CARD');
        if (this.segmentTipo == 'debito') {
            this.pagoForm.controls.tDebito.setValue(this.pagoForm.controls.tCredito.value);
        } else {
            this.pagoForm.controls.tCredito.setValue(this.pagoForm.controls.tDebito.value);
        }
    }

    viewInfo(tipo, ev) {
        if (tipo == 'ini') {
            this.selDiv = false;
            this.selInfo = '';
            // this.pagoForm.reset();
            this.pagoForm.controls.efectivo.setValue(null);
            this.pagoForm.controls.tDebito.setValue(null);
            this.pagoForm.controls.tCredito.setValue(null);
            this.pagoForm.controls.transferencia.setValue(null);
            this.pagoForm.controls.consignacion.setValue(null);
            this.pagoForm.controls.cheque.setValue(null);
            this.pagoForm.controls.nota.setValue(null);
            this.valActualiza(ev);
        } else {
            this.selDiv = true;
            this.selInfo = tipo;
            this.setValidators(tipo);
        }

    }
    opRapida(opc, ev) {
        switch (opc) {
            case 1:
                this.pagoForm.controls.efectivo.setValue(this.op1);
                break;
            case 2:
                this.pagoForm.controls.efectivo.setValue(this.op2);
                break;
            case 3:
                this.pagoForm.controls.efectivo.setValue(this.op3);
                break;
        }
        this.valActualiza(ev);
    }

    valActualiza(ev) {
        this.cambio = this.op1 - (this.pagoForm.controls.efectivo.value +
            this.pagoForm.controls.tDebito.value +
            this.pagoForm.controls.tCredito.value +
            this.pagoForm.controls.transferencia.value +
            this.pagoForm.controls.consignacion.value +
            this.pagoForm.controls.cheque.value);
        if (this.cambio > 0) {
            this.pCobrar = true;
        } else {
            this.pCobrar = false;
            this.cambio = this.cambio * -1;
        }
        this.pagoForm.controls.cambio.setValue(this.cambio);
        this.pagoForm.controls.total.setValue(
            this.pagoForm.controls.efectivo.value +
            this.pagoForm.controls.tDebito.value +
            this.pagoForm.controls.tCredito.value +
            this.pagoForm.controls.transferencia.value+
            this.pagoForm.controls.consignacion.value +
            this.pagoForm.controls.cheque.value
        );
    }
    async dismissModal(ev) {
        this.modalController.dismiss({
            'posVentaInfo': null
        })
    }

    pagar(ev) {
        this.modalController.dismiss({
            'posVentaInfo': this.pagoForm.getRawValue()
        });
    }

    async modalFuncionario(ev) {
        let selected = undefined;
        const modalFuncionario = await this.modalController.create({
            component: ModalTerceroComponent,
            componentProps: {
                'select': 'single',
                'tipo': this.tipo,
                'selected': selected,
                'terceroTipo': 'funcionario'
            }
        });
        await modalFuncionario.present();
        const modalReturn = await modalFuncionario.onDidDismiss();
        if (modalReturn.data.terceroInfo != null) {
            this.funcionario = modalReturn.data.terceroInfo;
            this.pagoForm.controls.vendedor.setValue(this.funcionario.nombre);
            this.pagoForm.controls.venId.setValue(this.funcionario.id);
        }
    }

    async funcionarioClear(ev) {
        ev.stopPropagation();
        this.funcionario = undefined;
        this.pagoForm.controls.vendedor.setValue(null);
        this.pagoForm.controls.venId.setValue(null);
    }
}
import { NgModule } from '@angular/core';
import { 
	ShortName, Numberformat, Search, Tel, Linkify, StrengthPass, EmbebedUrl, 
	TotalTime, Sizemb, RemoveSpaces, Capitalize, LowerCase, FormatDate, FormatDateTime
} from './filters.pipe';

@NgModule({
	declarations: [
		ShortName, Numberformat, Search, Tel, Linkify, StrengthPass, 
		EmbebedUrl, TotalTime, Sizemb, RemoveSpaces, Capitalize, LowerCase, FormatDate, FormatDateTime
	],
	imports: [],
	exports: [
		ShortName, Numberformat, Search, Tel, Linkify, StrengthPass, 
		EmbebedUrl, TotalTime, Sizemb, RemoveSpaces, Capitalize, LowerCase, FormatDate, FormatDateTime
	],
})

export class PipesModule {}


import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, ModalController} from '@ionic/angular';
import { GlobalService } from './../../../../config/services/global.service';
import { FamiliaList } from './../../../../models/inventario.models';
//import { SelRol, SelMenuPermisos } from './../../../../models/global.models';

@Component({
  selector: 'app-modal-familia',
  templateUrl: './modal-familia.component.html',
  styleUrls: ['./modal-familia.component.scss'],
})
export class ModalFamiliaComponent implements OnInit {
	@ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
    @ViewChild('familiaSearchBar', null)familiaSearchBar;
    
    @Input() select: string;
    @Input() tipo: string;
    @Input() familiaID: string;
    @Input() famSelect: [];

    public familiaList: FamiliaList;
    //public rol:         SelRol;
    //public permisos:    SelMenuPermisos;

    public search_familia:   string  = '';
    public loadFamilia:      boolean = true;
    public loadFamiliaIni:   boolean = false;
    public from: any;
    public subscriptionFamilia:any;
    public itemSelect = [];

  	constructor( 
  		private globalService: GlobalService,
        private router: Router,
        public  modalController: ModalController
  	) {
        this.familiaList = {list: [], page: 0, pages: 0, iTotal: 0, search: ''}
    }

  	ngOnInit() {
  		if(this.famSelect !== undefined){
            this.itemSelect = this.famSelect;
        }
        this.list('ini', null, 'Cargando...');
        /*
        if(this.globalService.selects.familia.length <= 0){
            
        }else{
            this.asignList(this.globalService.selects.familia);
        }
        */
        this.subscriptionFamilia = this.globalService.changesFamiliaList.subscribe(familia => { 
            this.subscribeSave(familia)
        })
        this.focusSearch();
  	}

    ngOnDestroy() {
        this.subscriptionFamilia.unsubscribe();
    }

    subscribeSave(familia) {
        if(familia != null) {
            if(familia.tipo == 'add') {
                this.familiaList.list.unshift(familia.familiaList);
            }else{
                for (var i = this.familiaList.list.length - 1; i >= 0; i--) {
                    if(this.familiaList.list[i].id == familia.idLast){
                    	if(familia.tipo == 'edit') {
                            this.familiaList.list[i] = familia.familiaList;
                        }else{
                            this.familiaList.list.splice(i,1);
                        }
                        break
                    }
                }
            }
        }
    }

    familiaSearch(ev) {
        this.familiaList.page = 0;
        this.familiaList.list = [];
        this.list('ini', null, 'Buscando...');
    }

    familiaClearSearch(ev) {
    	if(this.familiaList.search != ''){
	        this.familiaList.page   = 0;
	        this.familiaList.search = '';
	        this.familiaList.list   = [];
	    }
    }

    focusSearch() {
        setTimeout(() =>{
        	this.familiaSearchBar.setFocus();
        },400);
    }

  	goInfinite(ev) {
        this.list('scroll', ev, '');
    }

    asignList(list: any){
        this.loadFamilia = false;
        if(this.itemSelect.length > 0){
            for (var i = 0; i < list.length; i++) {
                let noExiste = false;
                for (var j = this.itemSelect.length - 1; j >= 0; j--) {
                    if(list[i].id == this.itemSelect[j].id){
                        noExiste = false;
                        break;
                    }else{
                        noExiste = true;
                    }
                }
                if(noExiste){
                    this.familiaList.list.push(list[i] );
                }
            }
        }else{
            this.familiaList.list = list;
        }
    }

  	async list(from, ev, loadinSMS) {
  		const token       = await this.globalService.getToken();
        const entidad:any = await this.globalService.entidadDefault();

        this.familiaList.page      = this.familiaList.page+1;
        let params                 = {};
            params['page']         = this.familiaList.page;
            params['search']       = this.familiaList.search;
            params['appID']        = this.globalService.appID;
            params['sedeID']       = entidad.sedeID;
            params['familiaID']    = null;
        if(loadinSMS != ''){
        	//await this.globalService.showLoading('Cargando...');
        }
        this.loadFamilia = true;
        this.globalService.requestPost('invent/familia', params, 'jwt', token).subscribe(
            data => {
                if(data.validation == 'ok'){
                    let disabled = true;
                    if(data.list.length > 0){ 
                        this.globalService.selects.familia =data.list;
                        if(this.select == 'single') {
                            this.familiaList.list = data.list;
                        }else{
                            this.asignList( data.list);
                        }
                        disabled = false;
                    }
                    if(ev != null){
                        ev.target.disabled = disabled;
                        ev.target.complete();
                    }
                        
                    this.loadFamilia    = false;
                    this.loadFamiliaIni = true;
                    if(loadinSMS != ''){
                    	//this.globalService.ionViewDidLoad();
                    }
                }else{
                    this.globalService.failRequestHttp(data);
                }
            },
            error => {
                this.globalService.errorRequestHttp(error)
            }
        );
  	}

  	async newFamilia(ev) {
  		this.router.navigate(['/familia-addedit', {'tipo': 'add'}])
  		setTimeout(() =>{
            this.modalController.dismiss({'familiaInfo': null})
        },400);
    }

    async familiaSelect(item, ev) {

        if(this.select == 'single') {
            this.modalController.dismiss({
                'familiaInfo': item
            });
        }else if(this.select == 'singleMultiple') {
            this.itemSelect.push(item);
            this.modalController.dismiss({
                'familiaInfo': item
            });
        }else{
            this.itemSelect.push(item);
            for (var i = this.familiaList.list.length - 1; i >= 0; i--) {
                if(this.familiaList.list[i].id == item.id){
                    this.familiaList.list.splice(i,1);
                }
            }
        }
    }

    async familiaEliminar(item, ev) {
        for (var i = this.itemSelect.length - 1; i >= 0; i--) {
            if(this.itemSelect[i].id == item.id){
                this.itemSelect.splice(i,1);
            }
        }
        this.familiaList.list.push(item);
    }

    async dismissModal(ev) {
        this.modalController.dismiss({
            'familiaInfo': null
        })
    }
}




import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';

import { GlobalService } from '../../../../config/services/global.service';
import { loteTipo } from '../../../../core/constants/lote';
import { LoteList, RowLote } from '../../../../models/produccion.models';

@Component({
  selector: "app-modal-lote",
  templateUrl: "./modal-lote.component.html",
  styleUrls: ["./modal-lote.component.scss"],
})
export class ModalLoteComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild("loteSearchBar") loteSearchBar;

  @Input() select: string;
  @Input() tipo: string;
  @Input() loteTipo: string;
  @Input() selected: RowLote;

  public loteList: LoteList;
  public loadLote = true;
  public loadLoteIni = false;
  public showSearch = false;
  public from: any;
  public itemSelect = [];

  public tipoLoteFactory = loteTipo;

  constructor(
    private globalService: GlobalService,
    public modalController: ModalController
  ) {
    this.loteList = { list: [], page: 0, pages: 0, iTotal: 0, search: "" };
  }

  ngOnInit() {
    if (this.globalService.selects.lotes.length <= 0) {
      this.list("ini", null, "Cargando...");
    } else {
      this.loteList.list = this.globalService.selects.lotes;
      if (this.selected !== undefined || this.selected != null) {
        this.loteList.list = this.loteList.list.filter(
          (i) => i.id !== this.selected.id
        );
      }
    }
    this.focusSearch();
  }

  focusSearch() {
    setTimeout(() => {
      this.loteSearchBar.setFocus();
    }, 400);
  }

  goInfinite(ev) {
    this.list("scroll", ev, "");
  }

  async list(from, ev, loadinSMS) {
    const token = await this.globalService.getToken();
    const entidad: any = await this.globalService.entidadDefault();

    this.loteList.page = this.loteList.page + 1;
    const params = {};
    params["take"] = 500;
    params["page"] = this.loteList.page;
    params["search"] = this.loteList.search;
    params["appID"] = this.globalService.appID;
    params["sedeID"] = entidad.sedeID;
    params["loteID"] = null;
    params["loteTipo"] = this.loteTipo;
    this.loadLote = true;
    this.globalService.requestPost("prod/lote", params, "jwt", token).subscribe(
      (data) => {
        if (data.validation === "ok") {
          let disabled = true;
          if (data.list.length > 0) {
            this.globalService.selects.lotes = data.list;

            // -- para que no aparezca el lote seleccionado
            if (this.selected !== undefined) {
              data.list = data.list.filter((i) => i.id !== this.selected.id);
            }
            // -- this.loteList.list = data.list;
            data.list.map((row) => {
              this.loteList.list.push(row);
            });

            disabled = false;
          }

          if (ev != null) {
            ev.target.disabled = disabled;
            ev.target.complete();
          }

          this.loadLote = false;
          this.loadLoteIni = true;
          if (this.showSearch) {
            this.focusSearch();
          }
        } else {
          this.globalService.failRequestHttp(data);
        }
      },
      (error) => {
        this.globalService.errorRequestHttp(error);
      }
    );
  }

  async loteSelect(item, ev) {
    if (this.select === "single") {
      this.modalController.dismiss({
        loteInfo: item,
      });
    } else if (this.select === "singleMultiple") {
      this.itemSelect.push(item);
      this.modalController.dismiss({
        loteInfo: item,
      });
    }
  }

  async dismissModal(ev) {
    this.modalController.dismiss({
      loteInfo: null,
    });
  }
}

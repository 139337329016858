import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, ModalController} from '@ionic/angular';
import { GlobalService } from './../../../../config/services/global.service';
import { FacturaList } from './../../../../models/facturacion.models';

@Component({
    selector: 'app-modal-factura-venta',
    templateUrl: './modal-factura-venta.component.html',
    styleUrls: ['./modal-factura-venta.component.scss'],
})
export class ModalFacturaVentaComponent implements OnInit {
    @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
    @ViewChild('facturaSearchBar', null)facturaSearchBar;
    
    @Input() select: string;
    @Input() tipo: string;
    @Input() facturaID: number;
    @Input() clienteID: number;
    @Input() facturaSelPrev: [];
    @Input() from: string;

    public facturaList: FacturaList;
    public search_factura:   string  = '';
    public loadFactura:      boolean = true;
    public loadFacturaIni:   boolean = false;
    public subscriptionFactura:any;
    public facturaSelect = [];

  	constructor( 
  		private globalService: GlobalService,
        private router: Router,
        public  modalController: ModalController
  	) {
        this.facturaList = {list: [], page: 0, pages: 0, iTotal: 0, search: ''}
    }

  	ngOnInit() {
  		if(this.facturaSelPrev != null){
            this.facturaSelect = this.facturaSelPrev;
        }
        
        if(this.globalService.selects.facturas.length <= 0){
            this.list('ini', null, 'Cargando...');
        }else{
            this.asignList(this.globalService.selects.facturas);
        }
        this.subscriptionFactura = this.globalService.changesFVentaList.subscribe(factura => { 
            this.subscribeSave(factura)
        })
        this.focusSearch();
  	}

    ngOnDestroy() {
        this.subscriptionFactura.unsubscribe();
    }

    subscribeSave(factura) {
        if(factura != null) {
            if(factura.tipo == 'add') {
                this.facturaList.list.unshift(factura.facturaList);
            }else{
                for (var i = this.facturaList.list.length - 1; i >= 0; i--) {
                    if(this.facturaList.list[i].id == factura.idLast){
                    	if(factura.tipo == 'edit') {
                            this.facturaList.list[i] = factura.facturaList;
                        }else{
                            this.facturaList.list.splice(i,1);
                        }
                        break
                    }
                }
            }
        }
    }

    facturaSearch(ev) {
        this.facturaList.page = 0;
        this.facturaList.list = [];
        this.list('ini', null, 'Buscando...');
    }

    facturaClearSearch(ev) {
    	if(this.facturaList.search != ''){
	        this.facturaList.page   = 0;
	        this.facturaList.search = '';
	        this.facturaList.list   = [];
	    }
    }

    focusSearch() {
        setTimeout(() =>{
        	this.facturaSearchBar.setFocus();
        },400);
    }

  	goInfinite(ev) {
        if(this.globalService.selects.facturas.length <= 0){
            this.list('scroll', ev, '');
        }else{
            ev.target.disabled = true;
            ev.target.complete();
        }
        
    }

    asignList(list: any){
    	this.facturaList.page = 1;
        this.loadFactura = false;
        if(this.facturaSelect.length > 0){
            /*
            for (var j = this.facturaSelect.length - 1; j >= 0; j--) {
                this.facturaList.list =  list.list.filter(i => i.id !== this.facturaSelect[j].id);
            }
            */
            for (var i = 0; i < list.length; i++) {
                let noExiste = false;
                for (var j = this.facturaSelect.length - 1; j >= 0; j--) {
                    if(list[i].id == this.facturaSelect[j].id){
                        noExiste = false;
                        break;
                    }else{
                        noExiste = true;
                    }
                }
                if(noExiste){
                    this.facturaList.list.push(list[i]);
                }
            }
        }else{
            //this.facturaList.list = list;
            for (var i = 0; i < list.length; i++) {
                this.facturaList.list.push( list[i] );
            }
        }
    }

  	async list(from, ev, loadinSMS) {
  		const token       = await this.globalService.getToken();
        const entidad:any = await this.globalService.entidadDefault();

        this.facturaList.page      = this.facturaList.page+1;
        let params              = {};
            params['take']      = 1000;
            params['page']      = this.facturaList.page;
            params['search']    = this.facturaList.search;
            params['appID']     = this.globalService.appID;
            params['sedeID']    = entidad.sedeID;
            params['itemID']    = null;
            params['clienteID'] = this.clienteID;
            params['from']      = this.from;
        if(loadinSMS != ''){
        	//await this.globalService.showLoading('Cargando...');
        }
        this.loadFactura = true;
        this.globalService.requestPost('factura/factura', params, 'jwt', token).subscribe(
            data => {
                if(data.validation == 'ok'){
                    let disabled = true;
                    if(data.list.length > 0){ 
                        this.globalService.selects.facturas =data.list;
                        if(this.select == 'single') {
                            //this.facturaList.list = data.list;
                            for (var i = 0; i < data.list.length; i++) {
                                this.facturaList.list.push( data.list[i] );
                            }
                        }else{
                            this.asignList( data.list);
                        }
                        disabled = false;
                    }
                    if(ev != null){
                        ev.target.disabled = disabled;
                        ev.target.complete();
                    }
                        
                    this.loadFactura    = false;
                    this.loadFacturaIni = true;
                    if(loadinSMS != ''){
                    	//this.globalService.ionViewDidLoad();
                    }
                }else{
                    this.globalService.failRequestHttp(data);
                }
            },
            error => {
                this.globalService.errorRequestHttp(error)
            }
        );
  	}


    async facturaSelected(factura, ev) {        
        if(this.select == 'single') {
            this.modalController.dismiss({
                'facturaInfo': factura
            });
        }else if(this.select == 'singleMultiple') {
            this.facturaSelect.push(factura);
            this.modalController.dismiss({
                'facturaInfo': factura
            });
        }else{
            this.facturaSelect.push(factura);
            for (var i = this.facturaList.list.length - 1; i >= 0; i--) {
                if(this.facturaList.list[i].id == factura.id){
                    this.facturaList.list.splice(i,1);
                }
            }
        }
    }

    async facturaEliminar(factura, ev) {
        for (var i = this.facturaSelect.length - 1; i >= 0; i--) {
            if(this.facturaSelect[i].id == factura.id){
                this.facturaSelect.splice(i,1);
            }
        }
        this.facturaList.list.push(factura);
    }

    async dismissModal(ev) {
        this.modalController.dismiss({
            'facturaInfo': null
        })
    }
}




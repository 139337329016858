import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { RowAjuste, RowBodega, RowItem } from 'src/app/models/inventario.models';
import { GlobalService } from 'src/app/config/services/global.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Ionic4DatepickerModalComponent } from '@logisticinfotech/ionic4-datepicker';
import { ModalBodegaComponent } from 'src/app/components/modal/inventario/modal-bodega/modal-bodega.component';
import { ModalItemComponent } from '../../inventario/modal-item/modal-item.component';
import { BodegaList } from '../../../../models/inventario.models';

@Component({
	selector: 'app-modal-pos-ajuste',
	templateUrl: './modal-pos-ajuste.component.html',
	styleUrls: ['./modal-pos-ajuste.component.scss'],
})
export class ModalPosAjusteComponent implements OnInit {
	@ViewChild('cantAjuste', null) cantAjuste;
	@Input() itemPos: RowItem;
	@Input() itemBodegaId: number;
	public ajusteInfo: RowAjuste;
	//public ajusteDetail: AjusteDetail;
	public bodegaList:    BodegaList;
	public bodega: RowBodega;
	public itemInfo: RowItem;

	public tipo: any;
	public idData: any;
	public itemDetSel: any = null || [];
	public validCant: string = '';
	public loadItem: boolean = true;
	public ajusteForm: FormGroup = this.formBuilder.group({
		tipo: ['add'],
		id: [''],
		idEdit: [''],
		sedeID: [null],
		appID: [null],
		fecha: [moment().format('DD/MM/YYYY'), Validators.compose([Validators.required])],
		descripcion: ['Ajuste de invetario facturacion POS', Validators.compose([Validators.required])],
		estado: [true, Validators.compose([Validators.required])],
		bodega: [null, Validators.compose([Validators.required])],
		itemID: [null, Validators.compose([Validators.required])],
		tipoAj: ['incremento', Validators.compose([Validators.required])],

		cantBodega: [{ value: 0, readonly: true }, Validators.compose([Validators.minLength(0), Validators.min(0), Validators.required])],
		cantAjuste: [0, Validators.compose([Validators.minLength(1), Validators.min(1), Validators.required])],
		cantTotal: [{ value: 0, readonly: true }, Validators.compose([Validators.minLength(0), Validators.min(0), Validators.required])],
		precio: [{ value: 0, disabled: false }, Validators.compose([Validators.minLength(0), Validators.min(0), Validators.required])],
		referencia: [{ value: 0, readonly: true }],
		foto: [null],
		codigo: [null],
		nombre: [null]
	});

	constructor(
		public globalService: GlobalService,
		private formBuilder: FormBuilder,
		private activatedRoute: ActivatedRoute,
		public modalController: ModalController
	) {
		this.bodegaList = {list: [], page: 0, pages: 0, iTotal: 0, search: ''}
	}

	ngOnInit() {
		this.idData = this.itemPos.id
		this.tipo = 'add'
		this.ajusteItemInfo();
	}

	async ajusteItemInfo() {
		await this.list();
		this.ajusteForm.controls.itemID.setValue(this.itemPos.id)
		this.ajusteForm.controls.cantBodega.setValue(this.itemPos.itb_cantidad)
		this.ajusteForm.controls.cantTotal.setValue(this.itemPos.itb_cantidad)
		this.ajusteForm.controls.foto.setValue(this.itemPos.itm_foto)
		this.ajusteForm.controls.codigo.setValue(this.itemPos.itm_codigo)
		this.ajusteForm.controls.referencia.setValue(this.itemPos.itm_referencia)
		this.ajusteForm.controls.nombre.setValue(this.itemPos.itm_nombre)
		this.ajusteForm.controls.precio.setValue(this.itemPos.itm_precio_venta)
		
		setTimeout(() => {
			this.ajusteForm.controls.cantAjuste.setValue(null)
			this.cantAjuste.setFocus();
		}, 500);
}

	async list() {
		const token       = await this.globalService.getToken();
		const entidad: any = await this.globalService.entidadDefault();

		let params = {};
		params['take'] = 100;
		params['page'] = 1
		params['search'] = null;
		params['appID'] = this.globalService.appID;
		params['sedeID'] = entidad.sedeID;
		params['bodegaID'] = null;
		this.globalService.requestPost('invent/bodega', params, 'jwt', token).subscribe(
			data => {
				if (data.validation == 'ok') {
					this.bodega = data.list.filter(i => i.id == this.itemBodegaId)[0];
					this.ajusteForm.controls.bodega.setValue(this.bodega.id)
				} else {
					this.globalService.failRequestHttp(data);
				}
			},
			error => {
				this.globalService.errorRequestHttp(error)
			}
		);
	}
	
  	async changeCantidad(tipo, ev){
  		tipo == null ? tipo = this.ajusteForm.controls.tipoAj.value : tipo = tipo;
  		if(tipo == 'disminucion'){
	  		if(this.ajusteForm.controls.cantBodega.value < this.ajusteForm.controls.cantAjuste.value){
	  			this.validCant = 'La cantidad debe ser menor a la de bodega.'
	  		}else{
	  			this.validCant = ''
	  		}
	  		this.ajusteForm.controls.cantTotal.setValue(this.ajusteForm.controls.cantBodega.value - this.ajusteForm.controls.cantAjuste.value)
	  	}else{
	  		this.validCant = ''
	  		this.ajusteForm.controls.cantTotal.setValue(this.ajusteForm.controls.cantBodega.value + this.ajusteForm.controls.cantAjuste.value)
	  	}
  	}

  	async listItem(bodegaID, itemID) {
  		this.loadItem = false;
  		const token       = await this.globalService.getToken();
        const entidad:any = await this.globalService.entidadDefault();

        let params                 = {};
        	params['take']         = 100;
            params['page']         = 1;
            params['search']       = null;
            params['itemID']       = itemID;
            params['appID']        = this.globalService.appID;
            params['sedeID']       = entidad.sedeID;
            params['bodegaID']     = bodegaID;
            params['from']         = 'ajuste';
            
        this.globalService.requestPost('invent/item', params, 'jwt', token).subscribe(
            data => {
                if(data.validation == 'ok'){
                    this.globalService.selects.item = data.list;
                }else{
                    this.globalService.failRequestHttp(data);
                }
            },
            error => {
                this.globalService.errorRequestHttp(error)
            }
        );
  	}

    async saveAjuste(ev) {
        await this.globalService.showLoading('Guardando...');
        const token       = await this.globalService.getToken();
        const entidad:any = await this.globalService.entidadDefault();
        const action = this.tipo == 'add' ? 'invent/addAjuste' : 'invent/editAjuste';
        
		this.ajusteForm.controls.sedeID.setValue(entidad.sedeID);
		this.ajusteForm.controls.appID.setValue(this.globalService.appID);

		const InfoSend = this.ajusteForm.getRawValue();
        this.globalService.requestPost(action, InfoSend, 'jwt', token).subscribe(
            data => {
                if(data.validation == 'ok'){
                    const paramSend = {'tipo': this.tipo, 'ajusteList': data.ajusteList, 'idLast': data.ajusteID}
					this.globalService.changesAjusteList.next(paramSend);
					this.modalController.dismiss({
						'posAjusteInfo': true
					});
                }else{
                    this.globalService.failRequestHttp(data);
                }
                this.globalService.ionViewDidLoad();
            },
            error => {
                this.globalService.errorRequestHttp(error)
            }
        );
    }

	async dismissModal(ev) {
        this.modalController.dismiss({
            'posAjusteInfo': false
        })
	}
	
}

import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, ModalController} from '@ionic/angular';
import { GlobalService } from './../../../../config/services/global.service';
import { CaracteristicaList, RowCaracteristica } from '../../../../models/inventario.models';

@Component({
    selector: 'app-modal-caracteristica',
    templateUrl: './modal-caracteristica.component.html',
    styleUrls: ['./modal-caracteristica.component.scss'],
})
export class ModalCaracteristicaComponent implements OnInit {

	@ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
    @ViewChild('caracteristicaSearchBar', null)caracteristicaSearchBar;
    
    @Input() select: string;
    @Input() tipo: string;
    @Input() selected: RowCaracteristica;

    public caracteristicaList:    CaracteristicaList;
	public search_caracteristica: string  = '';
	public loadCaracteristica:    boolean = true;
    public loadCaracteristicaIni: boolean = false;
    public showSearch:    boolean = false;
    public from: any;
    public itemSelect = [];

  	constructor( 
  		private globalService: GlobalService,
  		private router: Router,
  		public  modalController: ModalController
  	) {
        this.caracteristicaList = {list: [], page: 0, pages: 0, iTotal: 0, search: ''}
    }

  	ngOnInit() {
  		if(this.globalService.selects.caracteristica.length <= 0){
        	this.list('ini', null, 'Cargando...');
  		}else{
  			this.caracteristicaList.list = this.globalService.selects.caracteristica;
            if(this.selected !== undefined || this.selected != null)
  			    this.caracteristicaList.list = this.caracteristicaList.list.filter(i => i.id !== this.selected.id);
  		}
        this.focusSearch();
  	}
    
    focusSearch() {
        setTimeout(() =>{
        	this.caracteristicaSearchBar.setFocus();
        },400);
    }

  	goInfinite(ev) {
        this.list('scroll', ev, '');
    }


  	async list(from, ev, loadinSMS) {
  		const token       = await this.globalService.getToken();
        const entidad:any = await this.globalService.entidadDefault();

        this.caracteristicaList.page      = this.caracteristicaList.page+1;
        let params                 = {};
            params['take']         = 500;
            params['page']         = this.caracteristicaList.page;
            params['search']       = this.caracteristicaList.search;
            params['appID']        = this.globalService.appID;
            params['sedeID']       = entidad.sedeID;
            params['caracteristicaID']     = null;
        this.loadCaracteristica = true;
        this.globalService.requestPost('invent/caracteristica', params, 'jwt', token).subscribe(
            data => {
                if(data.validation == 'ok'){
                    let disabled = true;
                    if(data.list.length > 0){ 
                    	this.globalService.selects.caracteristica = data.list;

                    	//-- para que no aparezca la caracteristica seleccionada
                    	if(this.selected !== undefined){
                        	data.list =  data.list.filter(i => i.id !== this.selected.id);
                        }
                        //this.caracteristicaList.list = data.list;
                        for (var i = 0; i < data.list.length; i++) {
                            this.caracteristicaList.list.push( data.list[i] );
                        }
                        disabled = false;
                    }

                    if(ev != null){
                        ev.target.disabled = disabled;
                        ev.target.complete();
                    }
                    
                    this.loadCaracteristica    = false;
                    this.loadCaracteristicaIni = true;
                    if(this.showSearch){
                    	this.focusSearch();
                    }
                }else{
                    this.globalService.failRequestHttp(data);
                }
            },
            error => {
                this.globalService.errorRequestHttp(error)
            }
        );
  	}

  	async caracteristicaSelect(item, ev) {
        if(this.select == 'single') {
            this.modalController.dismiss({
                'caracteristicaInfo': item
            });
        }else if(this.select == 'singleMultiple') {
            this.itemSelect.push(item);
            this.modalController.dismiss({
                'caracteristicaInfo': item
            });
        }
    }

  	async dismissModal(ev) {
        this.modalController.dismiss({
            'caracteristicaInfo': null
        })
    }
}






import { AutosizeModule } from 'ngx-autosize';

/*-- agregados --*/
import { APP_BASE_HREF, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core'; //CUSTOM_ELEMENTS_SCHEMA
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
/*----------------*/
/*-- componentes --*/
import { MenuGeneralComponent } from './components/menu-general/menu-general.component';
import { ModalBancoComponent } from './components/modal/admin/modal-banco/modal-banco.component';
import { ModalCcostoComponent } from './components/modal/admin/modal-ccosto/modal-ccosto.component';
import {
  ModalConceptoComponent
} from './components/modal/admin/modal-concepto/modal-concepto.component';
import {
  ModalLocalizacionComponent
} from './components/modal/admin/modal-localizacion/modal-localizacion.component';
import { ModalPlazoComponent } from './components/modal/admin/modal-plazo/modal-plazo.component';
import { ModalPrecioComponent } from './components/modal/admin/modal-precio/modal-precio.component';
import {
  ModalRetencionComponent
} from './components/modal/admin/modal-retencion/modal-retencion.component';
import {
  ModalTipoDocComponent
} from './components/modal/admin/modal-tipo-doc/modal-tipo-doc.component';
import {
  ModalTipoPagoComponent
} from './components/modal/admin/modal-tipo-pago/modal-tipo-pago.component';
import {
  ModalPlanCuentasComponent
} from './components/modal/contabilidad/modal-plan-cuentas/modal-plan-cuentas.component';
import {
  ModalFacturaVentaComponent
} from './components/modal/facturacion/modal-factura-venta/modal-factura-venta.component';
import {
  ModalBodegaComponent
} from './components/modal/inventario/modal-bodega/modal-bodega.component';
import {
  ModalCaracteristicaComponent
} from './components/modal/inventario/modal-caracteristica/modal-caracteristica.component';
import {
  ModalCategoriaComponent
} from './components/modal/inventario/modal-categoria/modal-categoria.component';
import {
  ModalFamiliaComponent
} from './components/modal/inventario/modal-familia/modal-familia.component';
import {
  ModalImpuestoComponent
} from './components/modal/inventario/modal-impuesto/modal-impuesto.component';
import {
  ModalItemDetailaddComponent
} from './components/modal/inventario/modal-item-detailadd/modal-item-detailadd.component';
import { ModalItemComponent } from './components/modal/inventario/modal-item/modal-item.component';
import {
  ModalUnidadMedidaComponent
} from './components/modal/inventario/modal-unidad-medida/modal-unidad-medida.component';
//-- import { ModalFilterComponent } from './components/modal/informes/modal-filter/modal-filter.component';
//import { SelectComponentModule } from './components/select/select.component.module';
//import { HeaderReportComponentModule } from './components/header-report/header-report.component.module';
import {
  ModalPosAjusteComponent
} from './components/modal/pos/modal-pos-ajuste/modal-pos-ajuste.component';
import {
  ModalPosItemComponent
} from './components/modal/pos/modal-pos-item/modal-pos-item.component';
import {
  ModalPosVentaComponent
} from './components/modal/pos/modal-pos-venta/modal-pos-venta.component';
import {
  ModalColeccionComponent
} from './components/modal/produccion/modal-coleccion/modal-coleccion.component';
import { ModalLoteComponent } from './components/modal/produccion/modal-lote/modal-lote.component';
import {
  ModalTipoProduccionComponent
} from './components/modal/produccion/modal-tipo-produccion/modal-tipo-produccion.component';
import {
  ModalTerceroComponent
} from './components/modal/tercero/modal-tercero/modal-tercero.component';
import { ManageHttpInterceptor } from './config/interceptor/manage-http.interceptor';
//-- import { Ng2GoogleChartsModule } from 'ng2-google-charts';
//-- import { FileUploadModule } from 'ng2-file-upload';
//-- import { DatePickerModule } from 'ionic4-date-picker';
//-- import { Ionic4DatepickerModule } from '@logisticinfotech/ionic4-datepicker';
//-- import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
//-- import { AngularEditorModule } from '@kolkov/angular-editor';
//-- import { AngularFireModule } from '@angular/fire';
//-- import { AngularFireMessagingModule } from '@angular/fire/messaging';
//-- import { environment } from '../environments/environment';
/*----------------*/
/*-- servicios --*/
//-- import { GlobalService } from './config/services/global.service';
//-- import { AuthService } from './config/services/auth.service';
//-- import { SocketService } from './config/services/socket.service';
import { PipesModule } from './config/pipe/pipes.module';
import { HttpCancelService } from './config/services/http-cancel.service';

export function tokenGetter() {
  return this.globalService.getToken().then((token: any) => {
    return token;
  });
}

@NgModule({
  declarations: [
    AppComponent,
    MenuGeneralComponent,
    ModalFamiliaComponent,
    ModalLocalizacionComponent,
    ModalUnidadMedidaComponent,
    ModalCategoriaComponent,
    ModalImpuestoComponent,
    ModalItemDetailaddComponent,
    ModalBodegaComponent,
    ModalItemComponent,
    ModalTerceroComponent,
    ModalTipoDocComponent,
    ModalPlazoComponent,
    ModalPrecioComponent,
    ModalCcostoComponent,
    ModalRetencionComponent,
    ModalTipoPagoComponent,
    ModalBancoComponent,
    ModalFacturaVentaComponent,
    ModalConceptoComponent,
    ModalCaracteristicaComponent,
    ModalPlanCuentasComponent,
    ModalPosVentaComponent,
    ModalPosItemComponent,
    ModalPosAjusteComponent,
    ModalLoteComponent,
    ModalTipoProduccionComponent,
    ModalColeccionComponent,
  ],
  entryComponents: [
    MenuGeneralComponent,
    ModalFamiliaComponent,
    ModalLocalizacionComponent,
    ModalUnidadMedidaComponent,
    ModalCategoriaComponent,
    ModalImpuestoComponent,
    ModalItemDetailaddComponent,
    ModalBodegaComponent,
    ModalItemComponent,
    ModalTerceroComponent,
    ModalTipoDocComponent,
    ModalPlazoComponent,
    ModalPrecioComponent,
    ModalCcostoComponent,
    ModalRetencionComponent,
    ModalTipoPagoComponent,
    ModalBancoComponent,
    ModalFacturaVentaComponent,
    ModalConceptoComponent,
    ModalCaracteristicaComponent,
    ModalPlanCuentasComponent,
    ModalPosVentaComponent,
    ModalPosItemComponent,
    ModalPosAjusteComponent,
    ModalLoteComponent,
    ModalTipoProduccionComponent,
    ModalColeccionComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md',
      rippleEffect: true,
      scrollAssist: false,
      scrollPadding: false,
      animated: true,
    }),
    AppRoutingModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        throwNoTokenError: false,
        headerName: 'Authorization',
        authScheme: 'Bearer',
        tokenGetter: tokenGetter,
        whitelistedDomains: [
          'http://localhost:8888',
          'https://www.smartge.co:443',
          'https://www.smartge.co',
          'http://www.smartge.co',
        ],
        blacklistedRoutes: [
          'https://www.smartge.co:443/appsserver/empresarial/public/api/',
          'http://www.smartge.co:443/appsserver/empresarial/public/api/',
        ],
      },
    }),
    IonicStorageModule.forRoot({
      name: 'smartge_empresarial',
      driverOrder: ['indexeddb', 'sqlite', 'websql'],
    }),
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    AutosizeModule,
    //HeaderReportComponentModule
    //-- Ng2GoogleChartsModule,
    //-- FileUploadModule,
    //-- DatePickerModule,
    //-- SelectComponentModule,
    //-- Ionic4DatepickerModule,
    //-- CKEditorModule,
    //-- AngularEditorModule,
    //-- AngularFireModule.initializeApp(environment.firebase),
    //-- AngularFireMessagingModule
  ],
  providers: [
    StatusBar,
    SplashScreen,

    HttpCancelService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ManageHttpInterceptor,
      multi: true,
    },
    //-- SocketService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: APP_BASE_HREF, useValue: window['base-href'] },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
  //schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}

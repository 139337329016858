import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, ModalController} from '@ionic/angular';
import { GlobalService } from './../../../../config/services/global.service';
import { SelPrecio } from '../../../../models/global.models';

@Component({
    selector: 'app-modal-precio',
    templateUrl: './modal-precio.component.html',
    styleUrls: ['./modal-precio.component.scss'],
})
export class ModalPrecioComponent implements OnInit {
    @ViewChild('precioSearchBar')precioSearchBar;
    
    @Input() select: string;
    @Input() tipo: string;

    public precioList:any;    //SelPrecio;
	  //public precioSearch: string  = '';
	  public loadPrecio:    boolean = true;
    public loadPrecioIni: boolean = false;
    public showSearch:    boolean = false;
    public from: any;
    public itemSelect = [];

  	constructor( 
  		private globalService: GlobalService,
  		private router: Router,
  		public  modalController: ModalController
  	) {
  		this.precioList = {list: [], page: 0, pages: 0, iTotal: 0, search: ''}
  	}

  	ngOnInit() {
  		if(this.globalService.selects.precios.length <= 0){
        	this.list('ini', null, 'Cargando...');
  		}else{
  			this.precioList.list = this.globalService.selects.precios;
  		}
        this.focusSearch();
  	}
    
    focusSearch() {
        setTimeout(() =>{
        	this.precioSearchBar.setFocus();
        },400);
    }

  	async list(from, ev, loadinSMS) {
  		const token       = await this.globalService.getToken();
        const entidad:any = await this.globalService.entidadDefault();

        this.precioList.page       = this.precioList.page+1;
        let params                 = {};
            params['take']         = 500;
            params['page']         = this.precioList.page;
            params['search']       = this.precioList.search;
            params['appID']        = this.globalService.appID;
            params['sedeID']       = entidad.sedeID;
            params['precioID']     = null;
        this.loadPrecio = true;
        this.globalService.requestPost('global/precio', params, 'jwt', token).subscribe(
            data => {
                if(data.validation == 'ok'){
                    let disabled = true;
                    if(data.precio.length > 0){ 
                    	this.globalService.selects.precios = data.precio;
                        this.precioList.list = data.precio;
                        disabled = false;
                    }
                    this.loadPrecio    = false;
                    this.loadPrecioIni = true;
                    if(this.showSearch){
                    	this.focusSearch();
                    }
                }else{
                    this.globalService.failRequestHttp(data);
                }
            },
            error => {
                this.globalService.errorRequestHttp(error)
            }
        );
  	}

  	async precioSelect(item, ev) {
        if(this.select == 'single') {
            this.modalController.dismiss({
                'precioInfo': item
            });
        }else if(this.select == 'singleMultiple') {
            this.itemSelect.push(item);
            this.modalController.dismiss({
                'precioInfo': item
            });
        }
    }

  	async dismissModal(ev) {
        this.modalController.dismiss({
            'precioInfo': null
        })
    }
}



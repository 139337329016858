import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalController} from '@ionic/angular';
import { GlobalService } from './../../../../config/services/global.service';
import { LocalizacionList} from './../../../../models/global.models';

@Component({
    selector: 'app-modal-localizacion',
    templateUrl: './modal-localizacion.component.html',
    styleUrls: ['./modal-localizacion.component.scss'],
})
export class ModalLocalizacionComponent implements OnInit {
    @ViewChild('rowSearchBar')rowSearchBar;
    
    @Input() select: string;
    @Input() rowID: string;
    @Input() rowSelects: [];
    @Input() nameSelect:  string;
    @Input() titleSelect: string;
    @Input() paramID: number;

    public rowList: LocalizacionList;
    public search_row:   string  = '';
    public loadRow:      boolean = false;
    public loadRowIni:   boolean = false;
    public from: any;
    public itemSelect = [];

  	constructor( 
  		private globalService: GlobalService,
        public  modalController: ModalController
  	) {}

  	ngOnInit() {
  		this.rowList    = {list: this.rowSelects, page: 0, pages: 0, iTotal: 0, search: ''}
        this.loadRowIni = true;
        this.focusSearch();
  	}

    focusSearch() {
        setTimeout(() =>{
        	this.rowSearchBar.setFocus();
        },300);
    }

    async rowSelect(item, ev) {
        this.modalController.dismiss({
            'rowInfo': item
        });
    }

    async dismissModal(ev) {
        this.modalController.dismiss({
            'rowInfo': null
        })
    }
}




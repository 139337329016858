import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

import { GlobalService } from '../../../../config/services/global.service';

@Component({
	selector: 'app-modal-tipo-doc',
	templateUrl: './modal-tipo-doc.component.html',
	styleUrls: ['./modal-tipo-doc.component.scss'],
})
export class ModalTipoDocComponent implements OnInit {
	@ViewChild('tipoDocSearchBar') tipoDocSearchBar;

	@Input() select: string;
	@Input() tipo: string;
	@Input() clase: string;
	@Input() cont: string;

	public tipoDocList: any; //SelTipoDoc;
	//public tipoDocSearch: string  = '';
	public loadTipoDoc: boolean = true;
	public loadTipoDocIni: boolean = false;
	public showSearch: boolean = false;
	public from: any;
	public itemSelect = [];

	constructor(
		private globalService: GlobalService,
		private router: Router,
		public modalController: ModalController
	) {
		this.tipoDocList = { list: [], page: 0, pages: 0, iTotal: 0, search: '' };
	}

	ngOnInit() {
		if (this.globalService.selects.tipoDoc.length <= 0) {
			this.list('ini', null, 'Cargando...');
		} else {
			this.tipoDocList.list = this.globalService.selects.tipoDoc;
		}
		this.focusSearch();
	}

	focusSearch() {
		setTimeout(() => {
			this.tipoDocSearchBar.setFocus();
		}, 400);
	}

	async list(from, ev, loadinSMS) {
		const token = await this.globalService.getToken();
		const entidad: any = await this.globalService.entidadDefault();

		this.tipoDocList.page = this.tipoDocList.page + 1;
		let params = {};
		params['take'] = 500;
		params['page'] = this.tipoDocList.page;
		params['search'] = this.tipoDocList.search;
		params['appID'] = this.globalService.appID;
		params['sedeID'] = entidad.sedeID;
		params['tipoDocID'] = null;
		params['clase'] = this.clase;
		params['cont'] = this.cont; //ingreso
		this.loadTipoDoc = true;
		this.globalService
			.requestPost('global/tipoDoc', params, 'jwt', token)
			.subscribe(
				(data) => {
					if (data.validation == 'ok') {
						let disabled = true;
						if (data.list.length > 0) {
							this.globalService.selects.tipoDoc = data.list;
							this.tipoDocList.list = data.list;
							disabled = false;
						}
						this.loadTipoDoc = false;
						this.loadTipoDocIni = true;
						if (this.showSearch) {
							this.focusSearch();
						}
					} else {
						this.globalService.failRequestHttp(data);
					}
				},
				(error) => {
					this.globalService.errorRequestHttp(error);
				}
			);
	}

	async tipoDocSelect(item, ev) {
		if (this.select == 'single') {
			this.modalController.dismiss({
				tipoDocInfo: item,
			});
		} else if (this.select == 'singleMultiple') {
			this.itemSelect.push(item);
			this.modalController.dismiss({
				tipoDocInfo: item,
			});
		}
	}

	async dismissModal(ev) {
		this.modalController.dismiss({
			tipoDocInfo: null,
		});
	}
}

import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { GlobalService } from "src/app/config/services/global.service";
import { ModalController } from "@ionic/angular";
import { ModalImpuestoComponent } from "../../inventario/modal-impuesto/modal-impuesto.component";
import { RowItem } from 'src/app/models/inventario.models';

@Component({
    selector: "app-modal-pos-item",
    templateUrl: "./modal-pos-item.component.html",
    styleUrls: ["./modal-pos-item.component.scss"],
})
export class ModalPosItemComponent implements OnInit {
    @Input() select: string;
    @Input() tipo: string;
    @Input() itemPos: RowItem;
    @Input() from?: string;
    @ViewChild("cantFactura") cantFactura;
    public impuesto: any;
    public itemInfo: RowItem;
    public valid = { precio: "", cantidad: "", total: "" };
    public total: any = 0;
    public desValor: any = 0;
    public ctrlTotal: boolean = true;

    public formItemPos: FormGroup = this.formBuilder.group({
        nombre:         [''],
        referencia:     [''],
        descuento:      [null],
        precio:         [null],
        cantidad:       [null],
        total:          [null],
        impuestoID:     [null],
        impuesto:       [{value: 0, disabled: false}, Validators.compose([Validators.minLength(0), Validators.min(0) ])]
    });

    constructor(
        public globalService: GlobalService,
        private formBuilder: FormBuilder,
        public modalController: ModalController
    ) { }

    ngOnInit() {
        this.formItemPos.controls.nombre.setValue(this.itemPos.itm_nombre);
        this.formItemPos.controls.referencia.setValue(this.itemPos.itm_referencia);
        this.formItemPos.controls.descuento.setValue(this.itemPos.des_valor);
        this.formItemPos.controls.precio.setValue(this.itemPos?.itm_precio_venta ?? this.itemPos?.itm_precio);
        this.formItemPos.controls.cantidad.setValue(this.itemPos.fad_cantidad);
        this.formItemPos.controls.impuestoID.setValue(this.itemPos.impID);
        this.formItemPos.controls.impuesto.setValue(this.itemPos.imp_valor);
        this.getImpuesto(this.itemPos.impID);
        this.calcTotal('');
    }

    async getImpuesto(impuestoID) {
        const token       = await this.globalService.getToken();
        const entidad:any = await this.globalService.entidadDefault();
        let params                 = {};
            params['take']         = 500;
            params['appID']        = this.globalService.appID;
            params['sedeID']       = entidad.sedeID;
            params['impuestoID']   = impuestoID;
        this.globalService.requestPost('global/impuesto', params, 'jwt', token).subscribe(
            data => {
                if(data.validation == 'ok'){
                    if(data.list.length > 0){ 
                        if(impuestoID != null){
                            this.impuesto = data.list[0];
                        }else{
                            this.globalService.selects.impuesto = data.list;
                        }
                    }
                }else{
                    this.globalService.failRequestHttp(data);
                }
            },
            error => {
                this.globalService.errorRequestHttp(error)
            }
        );
    }
    

    async changeCantidad(ev) {
        if ( ((this.itemPos.itb_cantidad + this.itemPos.fad_cantidad) < this.formItemPos.controls.cantidad.value)
            && this.from !== 'compra') {
            this.valid.cantidad = "La cantidad debe ser menor a la de bodega.";
        } else {
            this.valid.cantidad = "";
            this.calcTotal('');
        }
    }

    async calcItemVal(ev) {
        if (this.formItemPos.controls.cantidad.value && this.ctrlTotal) {
            let desc = 0;
            let valItem = 0;
            this.ctrlTotal = false;
            if (this.formItemPos.controls.descuento) {
                desc = (((this.formItemPos.controls.total.value / 
                    (100 - this.formItemPos.controls.descuento.value) * 100)) -
                    this.formItemPos.controls.total.value) / 
                    this.formItemPos.controls.cantidad.value    ;
                valItem = (this.formItemPos.controls.total.value / 
                    this.formItemPos.controls.cantidad.value) + desc;
                this.formItemPos.controls.precio.setValue(valItem);
                this.desValor = this.formItemPos.controls.precio.value - 
                    ((this.formItemPos.controls.precio.value * this.formItemPos.controls.descuento.value) / 100);
            }else{
                valItem = (this.formItemPos.controls.total.value / 
                    this.formItemPos.controls.cantidad.value);
                    this.formItemPos.controls.precio.setValue(valItem);
            }
        }
        setTimeout(() => {
            this.ctrlTotal = true;
        }, 50);
    }

    async calcTotal(ev) {
            if (this.ctrlTotal) {
                let desc = 0;
                let total = 0;        
                this.ctrlTotal = false;
                if (this.formItemPos.controls.cantidad.value) {
                    total = this.formItemPos.controls.precio.value * this.formItemPos.controls.cantidad.value;
                }
                if (this.formItemPos.controls.descuento.value) {
                    desc = (total * this.formItemPos.controls.descuento.value) / 100;
                }

                total += -desc;
                this.desValor = this.formItemPos.controls.precio.value - ((this.formItemPos.controls.precio.value * this.formItemPos.controls.descuento.value) / 100);
                    this.formItemPos.controls.total.setValue(total);
            }
            setTimeout(() => {
                this.ctrlTotal = true;
            }, 50);
    }

    async modalImpuesto(ev) {
        let selected = undefined;
        const modalImpuesto = await this.modalController.create({
            component: ModalImpuestoComponent,
            componentProps: {
                select: "single",
                tipo: this.tipo,
                selected: selected,
            },
        });
        await modalImpuesto.present();
        const modalReturn = await modalImpuesto.onDidDismiss();
        if (modalReturn.data.impuestoInfo != null) {
            this.impuesto = modalReturn.data.impuestoInfo;
            this.formItemPos.controls.impuestoID.setValue(this.impuesto.id);
            this.formItemPos.controls.impuesto.setValue(this.impuesto.imp_valor);
        }
    }
    
    async impuestoClear(ev) {
        ev.stopPropagation();
        this.impuesto = undefined;
        this.formItemPos.controls.impuestoID.setValue(null);
        this.formItemPos.controls.impuesto.setValue(0);
    }
    async itemSave(action, ev) {
        this.itemPos.edit = false;
        this.itemPos.itm_nombre = this.formItemPos.controls.nombre.value;
        this.itemPos.itm_referencia = this.formItemPos.controls.referencia.value;
        this.itemPos.des_valor = this.formItemPos.controls.descuento.value;
        this.itemPos.itm_precio_venta = this.formItemPos.controls.precio.value;
        this.itemPos.des_precio_valor = this.desValor;
        this.itemPos.fad_cantidad = this.formItemPos.controls.cantidad.value;
        this.itemPos.impID =  this.formItemPos.controls.impuestoID.value;
        this.itemPos.imp_valor = this.formItemPos.controls.impuesto.value;
        this.modalController.dismiss({
            'posItemInfo': this.itemPos
        });
    }
    async dismissModal(ev) {
        this.modalController.dismiss({
            'itemInfo': null
        })
    }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';

import { GlobalService } from '../../../../config/services/global.service';
import { ColeccionList, RowColeccion } from '../../../../models/produccion.models';

@Component({
  selector: 'app-modal-coleccion',
  templateUrl: './modal-coleccion.component.html',
  styleUrls: ['./modal-coleccion.component.scss'],
})
export class ModalColeccionComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild('coleccionSearchBar') coleccionSearchBar;

  @Input() select: string;
  @Input() tipo: string;
  @Input() selected: RowColeccion;

  public coleccionList: ColeccionList;
  public loadColeccion = true;
  public loadColeccionIni = false;
  public showSearch = false;
  public from: any;
  public itemSelect = [];

  constructor(
    private globalService: GlobalService,
    private router: Router,
    public modalController: ModalController
  ) {
    this.coleccionList = { list: [], page: 0, pages: 0, iTotal: 0, search: '' };
  }

  ngOnInit() {
    if (this.globalService.selects.colecciones.length <= 0) {
      this.list('ini', null, 'Cargando...');
    } else {
      this.coleccionList.list = this.globalService.selects.colecciones;
      if (this.selected !== undefined || this.selected != null) {
        this.coleccionList.list = this.coleccionList.list.filter((i) => i.id !== this.selected.id);
      }
    }
    this.focusSearch();
  }

  focusSearch() {
    setTimeout(() => {
      this.coleccionSearchBar.setFocus();
    }, 400);
  }

  goInfinite(ev) {
    this.list('scroll', ev, '');
  }

  async list(from, ev, loadinSMS) {
    const token = await this.globalService.getToken();
    const entidad: any = await this.globalService.entidadDefault();

    this.coleccionList.page = this.coleccionList.page + 1;
    const params = {};
    params['take'] = 500;
    params['page'] = this.coleccionList.page;
    params['search'] = this.coleccionList.search;
    params['appID'] = this.globalService.appID;
    params['sedeID'] = entidad.sedeID;
    params['coleccionID'] = null;
    this.loadColeccion = true;
    this.globalService.requestPost('prod/coleccion', params, 'jwt', token).subscribe(
      (data) => {
        if (data.validation === 'ok') {
          let disabled = true;
          if (data.list.length > 0) {
            this.globalService.selects.colecciones = data.list;

            // -- para que no aparezca la coleccion seleccionada
            if (this.selected !== undefined) {
              data.list = data.list.filter((i) => i.id !== this.selected.id);
            }
            // -- this.coleccionList.list = data.list;
            data.list.map((row) => {
              this.coleccionList.list.push(row);
            });
            disabled = false;
          }

          if (ev != null) {
            ev.target.disabled = disabled;
            ev.target.complete();
          }

          this.loadColeccion = false;
          this.loadColeccionIni = true;
          if (this.showSearch) {
            this.focusSearch();
          }
        } else {
          this.globalService.failRequestHttp(data);
        }
      },
      (error) => {
        this.globalService.errorRequestHttp(error);
      }
    );
  }

  async coleccionSelect(item, ev) {
    if (this.select === 'single') {
      this.modalController.dismiss({
        coleccionInfo: item,
      });
    } else if (this.select === 'singleMultiple') {
      this.itemSelect.push(item);
      this.modalController.dismiss({
        coleccionInfo: item,
      });
    }
  }

  async dismissModal(ev) {
    this.modalController.dismiss({
      coleccionInfo: null,
    });
  }
}

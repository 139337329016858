import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';

import { GlobalService } from '../../../../config/services/global.service';
import { BodegaList, RowBodega } from '../../../../models/inventario.models';

@Component({
  selector: "app-modal-bodega",
  templateUrl: "./modal-bodega.component.html",
  styleUrls: ["./modal-bodega.component.scss"],
})
export class ModalBodegaComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild("bodegaSearchBar") bodegaSearchBar;

  @Input() select: string;
  @Input() tipo: string;
  @Input() selected: RowBodega;

  public bodegaList: BodegaList;
  public search_bodega = "";
  public loadBodega = true;
  public loadBodegaIni = false;
  public showSearch = false;
  public from: any;
  public itemSelect = [];

  constructor(
    private globalService: GlobalService,
    private router: Router,
    public modalController: ModalController
  ) {
    this.bodegaList = { list: [], page: 0, pages: 0, iTotal: 0, search: "" };
  }

  ngOnInit() {
    if (this.globalService.selects.bodega.length <= 0) {
      this.list("ini", null, "Cargando...");
    } else {
      this.bodegaList.list = this.globalService.selects.bodega;
      if (this.selected !== undefined || this.selected != null) {
        this.bodegaList.list = this.bodegaList.list.filter(
          (i) => i.id !== this.selected.id
        );
      }
    }
    this.focusSearch();
  }

  focusSearch() {
    setTimeout(() => {
      this.bodegaSearchBar.setFocus();
    }, 400);
  }

  goInfinite(ev) {
    this.list("scroll", ev, "");
  }

  async list(from, ev, loadinSMS) {
    const token = await this.globalService.getToken();
    const entidad: any = await this.globalService.entidadDefault();

    this.bodegaList.page = this.bodegaList.page + 1;
    const params = {};
    params["take"] = 500;
    params["page"] = this.bodegaList.page;
    params["search"] = this.bodegaList.search;
    params["appID"] = this.globalService.appID;
    params["sedeID"] = entidad.sedeID;
    params["bodegaID"] = null;
    this.loadBodega = true;
    this.globalService
      .requestPost("invent/bodega", params, "jwt", token)
      .subscribe(
        (data) => {
          if (data.validation == "ok") {
            let disabled = true;
            if (data.list.length > 0) {
              this.globalService.selects.bodega = data.list;

              // -- para que no aparezca la bodega seleccionada
              if (this.selected !== undefined) {
                data.list = data.list.filter((i) => i.id !== this.selected.id);
              }
              // this.bodegaList.list = data.list;
              // for (var i = 0; i < data.list.length; i++) {
              this.bodegaList.list.push(...data.list);
              // }
              disabled = false;
            }

            if (ev != null) {
              ev.target.disabled = disabled;
              ev.target.complete();
            }

            this.loadBodega = false;
            this.loadBodegaIni = true;
            if (this.showSearch) {
              this.focusSearch();
            }
          } else {
            this.globalService.failRequestHttp(data);
          }
        },
        (error) => {
          this.globalService.errorRequestHttp(error);
        }
      );
  }

  async bodegaSelect(item, ev) {
    if (this.select == "single") {
      this.modalController.dismiss({
        bodegaInfo: item,
      });
    } else if (this.select == "singleMultiple") {
      this.itemSelect.push(item);
      this.modalController.dismiss({
        bodegaInfo: item,
      });
    }
  }

  async dismissModal(ev) {
    this.modalController.dismiss({
      bodegaInfo: null,
    });
  }
}

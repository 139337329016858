import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IonInfiniteScroll, ModalController} from '@ionic/angular';
import { GlobalService } from './../../../../config/services/global.service';
import { ImpuestoList } from './../../../../models/facturacion.models';
//import { SelRol, SelMenuPermisos } from './../../../../models/global.models';

@Component({
  selector: 'app-modal-impuesto',
  templateUrl: './modal-impuesto.component.html',
  styleUrls: ['./modal-impuesto.component.scss'],
})
export class ModalImpuestoComponent implements OnInit {
	@ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
    @ViewChild('impuestoSearchBar')impuestoSearchBar;
    
    @Input() select: string;
    @Input() tipo: string;
    @Input() impuestoID: string;
    @Input() famSelect: [];

    public impuestoList: ImpuestoList;
    //public rol:         SelRol;
    //public permisos:    SelMenuPermisos;

    public search_impuesto:   string  = '';
    public loadImpuesto:      boolean = true;
    public loadImpuestoIni:   boolean = false;
    public from: any;
    public subscriptionImpuesto:any;
    public itemSelect = [];

  	constructor( 
  		private globalService: GlobalService,
        private router: Router,
        public  modalController: ModalController
  	) {
        this.impuestoList = {list: [], page: 0, pages: 0, iTotal: 0, search: ''}
    }

  	ngOnInit() {
  		if(this.famSelect !== undefined){
            this.itemSelect = this.famSelect;
        }
        if(this.globalService.selects.impuesto.length <= 0){
            this.list('ini', null, 'Cargando...');
        }else{
            this.impuestoList.list = this.globalService.selects.impuesto;
        }
        
        this.subscriptionImpuesto = this.globalService.changesImpuestoList.subscribe(impuesto => { 
            this.subscribeSave(impuesto)
        })
        this.focusSearch();
  	}

    ngOnDestroy() {
        this.subscriptionImpuesto.unsubscribe();
    }

    subscribeSave(impuesto) {
        if(impuesto != null) {
            if(impuesto.tipo == 'add') {
                this.impuestoList.list.unshift(impuesto.impuestoList);
            }else{
                for (var i = this.impuestoList.list.length - 1; i >= 0; i--) {
                    if(this.impuestoList.list[i].id == impuesto.idLast){
                    	if(impuesto.tipo == 'edit') {
                            this.impuestoList.list[i] = impuesto.impuestoList;
                        }else{
                            this.impuestoList.list.splice(i,1);
                        }
                        break
                    }
                }
            }
        }
    }

    impuestoSearch(ev) {
        this.impuestoList.page = 0;
        this.impuestoList.list = [];
        this.list('ini', null, 'Buscando...');
    }

    impuestoClearSearch(ev) {
    	if(this.impuestoList.search != ''){
	        this.impuestoList.page   = 0;
	        this.impuestoList.search = '';
	        this.impuestoList.list   = [];
	    }
    }

    focusSearch() {
        setTimeout(() =>{
        	this.impuestoSearchBar.setFocus();
        },400);
    }

  	goInfinite(ev) {
        this.list('scroll', ev, '');
    }

  	async list(from, ev, loadinSMS) {
  		const token       = await this.globalService.getToken();
        const entidad:any = await this.globalService.entidadDefault();

        this.impuestoList.page     = this.impuestoList.page+1;
        let params                 = {};
            params['take']         = 500;
            params['page']         = this.impuestoList.page;
            params['search']       = this.impuestoList.search;
            params['appID']        = this.globalService.appID;
            params['sedeID']       = entidad.sedeID;
            params['impuestoID']   = null;
        if(loadinSMS != ''){
        	//await this.globalService.showLoading('Cargando...');
        }
        this.loadImpuesto = true;
        this.globalService.requestPost('factura/impuesto', params, 'jwt', token).subscribe(
            data => {
                if(data.validation == 'ok'){
                    let disabled = true;
                    if(data.list.length > 0){ 
                        this.globalService.selects.impuesto = data.list;
                        if(this.select == 'single') {
                            this.impuestoList.list = data.list;
                        }else{
                            if(this.itemSelect.length > 0){
                                for (var i = 0; i < data.list.length; i++) {
                                    let noExiste = false;
                                    for (var j = this.itemSelect.length - 1; j >= 0; j--) {
                                        if(data.list[i].id == this.itemSelect[j].id){
                                            noExiste = false;
                                            break;
                                        }else{
                                            noExiste = true;
                                        }
                                    }
                                    if(noExiste){
                                        this.impuestoList.list.push( data.list[i] );
                                    }
                                }
                            }else{
                                this.impuestoList.list = data.list;
                            }
                        }
                        disabled = false;
                    }
                    if(ev != null){
                        ev.target.disabled = disabled;
                        ev.target.complete();
                    }
                        
                    this.loadImpuesto    = false;
                    this.loadImpuestoIni = true;
                    if(loadinSMS != ''){
                    	//this.globalService.ionViewDidLoad();
                    }
                }else{
                    this.globalService.failRequestHttp(data);
                }
            },
            error => {
                this.globalService.errorRequestHttp(error)
            }
        );
  	}

  	async newImpuesto(ev) {
  		this.router.navigate(['/impuesto-addedit', {'tipo': 'add'}])
  		setTimeout(() =>{
            this.modalController.dismiss({'impuestoInfo': null})
        },400);
    }

    async impuestoSelect(item, ev) {
        if(this.select == 'single') {
            this.modalController.dismiss({
                'impuestoInfo': item
            });
        }else if(this.select == 'singleMultiple') {
            this.itemSelect.push(item);
            this.modalController.dismiss({
                'impuestoInfo': item
            });
        }else{
            this.itemSelect.push(item);
            for (var i = this.impuestoList.list.length - 1; i >= 0; i--) {
                if(this.impuestoList.list[i].id == item.id){
                    this.impuestoList.list.splice(i,1);
                }
            }
        }
    }

    async impuestoEliminar(item, ev) {
        for (var i = this.itemSelect.length - 1; i >= 0; i--) {
            if(this.itemSelect[i].id == item.id){
                this.itemSelect.splice(i,1);
            }
        }
        this.impuestoList.list.push(item);
    }

    async dismissModal(ev) {
        this.modalController.dismiss({
            'impuestoInfo': null
        })
    }
}





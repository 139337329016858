import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './config/guards/auth.guard';

const routes: Routes = [
	{
		path: '',
		redirectTo: '/dashboard',
		pathMatch: 'full',
	},
	{
		path: 'login',
		loadChildren: () =>
			import('./login/login.module').then((m) => m.LoginPageModule),
	},
	/*
    { path: 'registro', loadChildren: './login/registro/registro.module#RegistroPageModule' },
    { path: 'confirmacion', loadChildren: './login/confirmacion/confirmacion.module#ConfirmacionPageModule' },
    { path: 'recuperar', loadChildren: './login/remember/remember.module#RememberPageModule' },
    { path: 'recuperar-confirmacion', loadChildren: './login/remember-confirmacion/remember-confirmacion.module#RememberConfirmacionPageModule' },
    { path: 'cambiar-pass', loadChildren: './login/change-pass/change-pass.module#ChangePassPageModule' },
    */
	// --------------------
	// -- --Administrativo-- --
	{
		path: 'concepto',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./admin/concepto/concepto.module').then(
				(m) => m.ConceptoPageModule
			),
	},
	{
		path: 'concepto-addedit/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./admin/concepto/concepto-addedit/concepto-addedit.module').then(
				(m) => m.ConceptoAddeditPageModule
			),
	},
	{
		path: 'retencion',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./admin/retencion/retencion.module').then(
				(m) => m.RetencionPageModule
			),
	},
	{
		path: 'retencion-addedit/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./admin/retencion/retencion-addedit/retencion-addedit.module'
			).then((m) => m.RetencionAddeditPageModule),
	},
	// -- --Inventario-- --
	{
		path: 'dashboard',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
	},
	{
		path: 'unidad-medida',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./inventario/unidad-medida/unidad-medida.module').then(
				(m) => m.UnidadMedidaPageModule
			),
	},
	{
		path: 'bodega',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./inventario/bodega/bodega.module').then(
				(m) => m.BodegaPageModule
			),
	},
	{
		path: 'bodega-addedit/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./inventario/bodega/bodega-addedit/bodega-addedit.module').then(
				(m) => m.BodegaAddeditPageModule
			),
	},
	{
		path: 'familia-categoria',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./inventario/familia-categoria/familia-categoria.module').then(
				(m) => m.FamiliaCategoriaPageModule
			),
	},
	{
		path: 'familia-addedit/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./inventario/familia-categoria/familia-addedit/familia-addedit.module'
			).then((m) => m.FamiliaAddeditPageModule),
	},
	{
		path: 'categoria-addedit/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./inventario/familia-categoria/categoria-addedit/categoria-addedit.module'
			).then((m) => m.CategoriaAddeditPageModule),
	},
	{
		path: 'item',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./inventario/item/item.module').then((m) => m.ItemPageModule),
	},
	{
		path: 'item-detail/:from/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./inventario/item/item-detail/item-detail.module').then(
				(m) => m.ItemDetailPageModule
			),
	},
	{
		path: 'item-addedit/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./inventario/item/item-addedit/item-addedit.module').then(
				(m) => m.ItemAddeditPageModule
			),
	},
	{
		path: 'traslado-item',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./inventario/traslado-item/traslado-item.module').then(
				(m) => m.TrasladoItemPageModule
			),
	},
	{
		path: 'traslado-item-addedit/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./inventario/traslado-item/traslado-item-addedit/traslado-item-addedit.module'
			).then((m) => m.TrasladoItemAddeditPageModule),
	},
	{
		path: 'traslado-item-detail/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./inventario/traslado-item/traslado-item-detail/traslado-item-detail.module'
			).then((m) => m.TrasladoItemDetailPageModule),
	},
	{
		path: 'item-descuento',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./inventario/item/item.module').then((m) => m.ItemPageModule),
	},
	{
		path: 'ajuste',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./inventario/ajuste/ajuste.module').then(
				(m) => m.AjustePageModule
			),
	},
	{
		path: 'ajuste-addedit/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./inventario/ajuste/ajuste-addedit/ajuste-addedit.module').then(
				(m) => m.AjusteAddeditPageModule
			),
	},
	{
		path: 'ajuste-detail/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./inventario/ajuste/ajuste-detail/ajuste-detail.module').then(
				(m) => m.AjusteDetailPageModule
			),
	},
	{
		path: 'inventario',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./inventario/inventario/inventario.module').then(
				(m) => m.InventarioPageModule
			),
	},
	{
		path: 'caracteristica',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./inventario/caracteristica/caracteristica.module').then(
				(m) => m.CaracteristicaPageModule
			),
	},
	{
		path: 'caracteristica-addedit/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./inventario/caracteristica/caracteristica-addedit/caracteristica-addedit.module'
			).then((m) => m.CaracteristicaAddeditPageModule),
	},
	{
		path: 'stock',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./inventario/stock/stock.module').then((m) => m.StockPageModule),
	},
	// -- --Inventario-- --
	// -- ---Terceros--- --
	{
		path: 'tercero-cliente',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./tercero/tercero.module').then((m) => m.TerceroPageModule),
	},
	{
		path: 'tercero-proveedor',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./tercero/tercero.module').then((m) => m.TerceroPageModule),
	},
	{
		path: 'tercero-detail/:from/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./tercero/tercero-detail/tercero-detail.module').then(
				(m) => m.TerceroDetailPageModule
			),
	},
	{
		path: 'tercero-addedit/:from/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./tercero/tercero-addedit/tercero-addedit.module').then(
				(m) => m.TerceroAddeditPageModule
			),
	},

	{
		path: 'funcionario',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./tercero/tercero.module').then((m) => m.TerceroPageModule),
	},
	{
		path: 'funcionario-detail/:from/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./tercero/tercero-detail/tercero-detail.module').then(
				(m) => m.TerceroDetailPageModule
			),
	},
	{
		path: 'funcionario-addedit/:from/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./tercero/tercero-addedit/tercero-addedit.module').then(
				(m) => m.TerceroAddeditPageModule
			),
	},
	// -- ---Facturacion--- --
	{
		path: 'factura-venta',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./facturaVenta/factura/factura.module').then(
				(m) => m.FacturaPageModule
			),
	},
	{
		path: 'factura-venta-detail/:from/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./facturaVenta/factura/factura-detail/factura-detail.module'
			).then((m) => m.FacturaDetailPageModule),
	},
	{
		path: 'factura-venta-addedit/:from/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./facturaVenta/factura/factura-addedit/factura-addedit.module'
			).then((m) => m.FacturaAddeditPageModule),
	},
	{
		path: 'pago-venta',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./facturaVenta/pago/pago.module').then((m) => m.PagoPageModule),
	},
	{
		path: 'pago-venta-detail/:from/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./facturaVenta/pago/pago-detail/pago-detail.module').then(
				(m) => m.PagoDetailPageModule
			),
	},
	{
		path: 'pago-venta-addedit/:from/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./facturaVenta/pago/pago-addedit/pago-addedit.module').then(
				(m) => m.PagoAddeditPageModule
			),
	},
	{
		path: 'factura-compra',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./facturaCompra/factura/factura.module').then(
				(m) => m.FacturaPageModule
			),
	},
	{
		path: 'factura-compra-addedit/:from/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./facturaCompra/factura/factura-addedit/factura-addedit.module'
			).then((m) => m.FacturaAddeditPageModule),
	},
	{
		path: 'factura-compra-detail/:from/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./facturaCompra/factura/factura-detail/factura-detail.module'
			).then((m) => m.FacturaDetailPageModule),
	},
	{
		path: 'impuesto',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./facturaVenta/impuesto/impuesto.module').then(
				(m) => m.ImpuestoPageModule
			),
	},
	{
		path: 'impuesto-addedit/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./facturaVenta/impuesto/impuesto-addedit/impuesto-addedit.module'
			).then((m) => m.ImpuestoAddeditPageModule),
	},
	{
		path: 'descuento',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./facturaVenta/descuento/descuento.module').then(
				(m) => m.DescuentoPageModule
			),
	},
	{
		path: 'descuento-addedit/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./facturaVenta/descuento/descuento-addedit/descuento-addedit.module'
			).then((m) => m.DescuentoAddeditPageModule),
	},
	{
		path: 'recibo-caja',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./facturaVenta/recibo-caja/recibo-caja.module').then(
				(m) => m.ReciboCajaPageModule
			),
	},
	{
		path: 'recibo-caja-detail/:from/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./facturaVenta/recibo-caja/recibo-caja-detail/recibo-caja-detail.module'
			).then((m) => m.ReciboCajaDetailPageModule),
	},
	{
		path: 'recibo-caja-addedit/:from/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./facturaVenta/recibo-caja/recibo-caja-addedit/recibo-caja-addedit.module'
			).then((m) => m.ReciboCajaAddeditPageModule),
	},
	{
		path: 'remision',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./facturaVenta/remision/remision.module').then(
				(m) => m.RemisionPageModule
			),
	},
	{
		path: 'remision-addedit/:from/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./facturaVenta/remision/remision-addedit/remision-addedit.module'
			).then((m) => m.RemisionAddeditPageModule),
	},
	{
		path: 'remision-detail/:from/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./facturaVenta/remision/remision-detail/remision-detail.module'
			).then((m) => m.RemisionDetailPageModule),
	},
	{
		path: 'comprobante-egreso',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./facturaCompra/comprobante-egreso/comprobante-egreso.module'
			).then((m) => m.ComprobanteEgresoPageModule),
	},
	{
		path: 'comprobante-egreso-detail/:from/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./facturaCompra/comprobante-egreso/comprobante-egreso-detail/comprobante-egreso-detail.module'
			).then((m) => m.ComprobanteEgresoDetailPageModule),
	},
	{
		path: 'comprobante-egreso-addedit/:from/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./facturaCompra/comprobante-egreso/comprobante-egreso-addedit/comprobante-egreso-addedit.module'
			).then((m) => m.ComprobanteEgresoAddeditPageModule),
	},
	// -- ---Contabilidad--- --
	{
		path: 'plan-cuentas',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./contabilidad/plan-cuentas/plan-cuentas.module').then(
				(m) => m.PlanCuentasPageModule
			),
	},
	{
		path: 'auxiliares',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./contabilidad/auxiliares/auxiliares.module').then(
				(m) => m.AuxiliaresPageModule
			),
	},
	{
		path: 'libro-mb',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./contabilidad/libro-mb/libro-mb.module').then(
				(m) => m.LibroMbPageModule
			),
	},

	// -- ---Pedidos--- --
	{
		path: 'carrito',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pedidos/carrito/carrito.module').then(
				(m) => m.CarritoPageModule
			),
	},
	{
		path: 'pedido',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pedidos/pedido/pedido.module').then((m) => m.PedidoPageModule),
	},
	{
		path: 'pedido-detail/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pedidos/pedido/pedido-detail/pedido-detail.module').then(
				(m) => m.PedidoDetailPageModule
			),
	},
	{
		path: 'pedido-addedit/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pedidos/pedido/pedido-addedit/pedido-addedit.module').then(
				(m) => m.PedidoAddeditPageModule
			),
	},
	{
		path: 'domiciliario',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pedidos/domiciliario/domiciliario.module').then(
				(m) => m.DomiciliarioPageModule
			),
	},
	{
		path: 'domiciliario-detail/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./pedidos/domiciliario/domiciliario-detail/domiciliario-detail.module'
			).then((m) => m.DomiciliarioDetailPageModule),
	},
	{
		path: 'domiciliario-addedit/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./pedidos/domiciliario/domiciliario-addedit/domiciliario-addedit.module'
			).then((m) => m.DomiciliarioAddeditPageModule),
	},
	{
		path: 'evento',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pedidos/evento/evento.module').then((m) => m.EventoPageModule),
	},
	{
		path: 'evento-addedit/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pedidos/evento/evento-addedit/evento-addedit.module').then(
				(m) => m.EventoAddeditPageModule
			),
	},
	// -- ---Informes--- --
	{
		path: 'informes-inventario',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./informes/inventario/inventario.module').then(
				(m) => m.InventarioPageModule
			),
	},
	{
		path: 'inv-bodega-existencia',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./informes/inventario/inv-bodega-existencia/inv-bodega-existencia.module'
			).then((m) => m.InvBodegaExistenciaPageModule),
	},
	{
		path: 'inv-ventas-utilidad',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./informes/inventario/inv-ventas-utilidad/inv-ventas-utilidad.module'
			).then((m) => m.InvVentasUtilidadPageModule),
	},
	{
		path: 'inv-cuadre-caja',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./informes/inventario/inv-cuadre-caja/inv-cuadre-caja.module'
			).then((m) => m.InvCuadreCajaPageModule),
	},
	{
		path: 'inv-cuadre-usuario',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./informes/inventario/inv-cuadre-usuario/inv-cuadre-usuario.module'
			).then((m) => m.InvCuadreUsuarioPageModule),
	},
	{
		path: 'inv-existencia',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./informes/inventario/inv-existencia/inv-existencia.module').then(
				(m) => m.InvExistenciaPageModule
			),
	},
	{
		path: 'inv-traslados',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./informes/inventario/inv-traslados/inv-traslados.module').then(
				(m) => m.InvTrasladosPageModule
			),
	},
	{
		path: 'inv-traslados-agrupado',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./informes/inventario/inv-traslados-agrupado/inv-traslados-agrupado.module'
			).then((m) => m.InvTrasladosAgrupadoPageModule),
	},

	{
		path: 'informes-facturacion',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./informes/facturacion/facturacion.module').then(
				(m) => m.FacturacionPageModule
			),
	},
	{
		path: 'fac-compras-ventas',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./informes/facturacion/fac-compras-ventas/fac-compras-ventas.module'
			).then((m) => m.FacComprasVentasPageModule),
	},
	{
		path: 'fac-compras-ventas-producto',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./informes/facturacion/fac-compras-ventas-producto/fac-compras-ventas-producto.module'
			).then((m) => m.FacComprasVentasProductoPageModule),
	},
	{
		path: 'fac-compras-ventas-factura',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./informes/facturacion/fac-compras-ventas-factura/fac-compras-ventas-factura.module'
			).then((m) => m.FacComprasVentasFacturaPageModule),
	},
	{
		path: 'fac-compras-ventas-producto-single',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./informes/facturacion/fac-compras-ventas-producto-single/fac-compras-ventas-producto-single.module'
			).then((m) => m.FacComprasVentasProductoSinglePageModule),
	},
	// -- ------POS------ --
	{
		path: 'pos-terminal',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pos/pos-terminal/pos-terminal.module').then(
				(m) => m.PosTerminalPageModule
			),
	},
	{
		path: 'pos-terminal-detail/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./pos/pos-terminal/pos-terminal-detail/pos-terminal-detail.module'
			).then((m) => m.PosTerminalDetailPageModule),
	},
	{
		path: 'pos-terminal-addedit/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./pos/pos-terminal/pos-terminal-addedit/pos-terminal-addedit.module'
			).then((m) => m.PosTerminalAddeditPageModule),
	},
	{
		path: 'pos-turno',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pos/pos-turno/pos-turno.module').then(
				(m) => m.PosTurnoPageModule
			),
	},
	{
		path: 'pos-turno-detail/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pos/pos-turno/pos-turno-detail/pos-turno-detail.module').then(
				(m) => m.PosTurnoDetailPageModule
			),
	},
	{
		path: 'pos-turno-addedit/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pos/pos-turno/pos-turno-addedit/pos-turno-addedit.module').then(
				(m) => m.PosTurnoAddeditPageModule
			),
	},
	{
		path: 'pos-venta',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pos/pos-venta/pos-venta.module').then(
				(m) => m.PosVentaPageModule
			),
	},
	{
		path: 'pos-compra',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pos/pos-compra/pos-compra.module').then(
				(m) => m.PosCompraPageModule
			),
	},
	{
		path: 'pos-factura',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pos/pos-factura/pos-factura.module').then(
				(m) => m.PosFacturaPageModule
			),
	},
	{
		path: 'pos-movimiento',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pos/pos-movimiento/pos-movimiento.module').then(
				(m) => m.PosMovimientoPageModule
			),
	},
	{
		path: 'pos-settings',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pos/pos-settings/pos-settings.module').then(
				(m) => m.PosSettingsPageModule
			),
	},
	{
		path: 'pos-factura-detail/:from/:id',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import(
				'./pos/pos-factura/pos-factura-detail/pos-factura-detail.module'
			).then((m) => m.PosFacturaDetailPageModule),
	},
	{
		path: 'facturacion',
		loadChildren: () =>
			import('./informes/facturacion/facturacion.module').then(
				(m) => m.FacturacionPageModule
			),
	},
	// -- ------Producción------ --
	{
		path: 'produccion',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./produccion/produccion.module').then((m) => m.ProduccionModule),
	},

	// -- ---xxxxxx--- --
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
